<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel">
            <div class="header" style="border:none">
                <h4 class="Title contctusHeader">Contact Us</h4>
                <div v-if="IsEmailSent">
                    <h5>
                        <span> {{ this.Message }}</span>
                    </h5>
                </div>

                <el-form v-if="!IsEmailSent" :model="UserDetails" :rules="rules" ref="ruleForm">
                    <el-form-item class="label" label="First name" prop="givenName">
                        <el-input v-model="UserDetails.givenName"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Second name" prop="surname">
                        <el-input v-model="UserDetails.surname"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Email address" prop="emailAddress">
                        <el-input v-model="UserDetails.emailAddress"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Organisation name" prop="organisationName">
                        <el-input v-model="UserDetails.organisationName"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Contact number" prop="contactNumber">
                        <el-input v-model="UserDetails.contactNumber"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Your message" prop="bodyMessage">
                        <el-input type="textarea" v-model="UserDetails.bodyMessage"></el-input>
                    </el-form-item>

                    <el-form-item class="navigation">
                        <el-button type="default" v-on:click.stop="$emit('back')">Cancel</el-button>
                        <el-button type="default" @click="resetForm('ruleForm')">Reset</el-button>
                        <el-button type="success" v-on:click.stop="SubmitContactForm('ruleForm')">Submit</el-button>
                    </el-form-item>
                </el-form>

                <div v-if="!IsEmailSent && false">
                    <label class="label">First name</label>
                    <input v-model="UserDetails.givenName" class="v-text-field input">
                    <br />
                    <label class="label">Second name</label>
                    <input v-model="UserDetails.surname" class="v-text-field input">
                    <br />
                    <label class="label">Email address</label>
                    <input v-model="UserDetails.emailAddress" class="v-text-field input">
                    <br />
                    <label class="label">Organisation name</label>
                    <input v-model="UserDetails.organisationName" class="v-text-field input">
                    <br />
                    <label class="label">Contact number</label>
                    <input v-model="UserDetails.contactNumber" class="v-text-field input">
                    <br />
                    <label class="label">Your message</label>
                    <textarea v-model="UserDetails.bodyMessage" placeholder="message" rows="8" class="v-text-field inputTextArea"></textarea>
                    <br />
                    <div class="navigation">
                        <el-button type="default" v-on:click.stop="$emit('back')">Cancel</el-button>
                        <el-button type="success" v-on:click.stop="SubmitContactForm()">Submit</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .el-form-item__label {
        color: none !important;
        font-size: 14px !important;
    }

    .label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }
</style>
<style scoped>
    .el-form-item__content {
        display: flex !important;
        flex-wrap: nowrap !important;
        align-items: center !important;
    }

    .el-form-item__label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .el-form-item {
        display: block !important;
        margin-bottom: 18px !important;
    }
    /* ipad Mini Landscape */
    /* @media only screen and (width:1024px) {
        .ModalBackGroundPanel {
            margin-top: 20px !important;
            height: 600px !important;
            padding-top: 0px !important;
        }
    }*/
    .navigation {
        margin-top: 5px !important;
        margin-left: 268px !important;
    }

    .el-button--success, .el-button--success:focus, .el-button--success:hover {
        position: static !important;
        height: 48px !important;
        background: #1E228C !important;
        border-radius: 8px !important;
        border: none !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        color: white !important;
        width: 100px !important;
    }

    .el-button--default, .el-button--default:focus, .el-button--default:hover {
        position: static !important;
        height: 48px !important;
        background: #FFFFFF !important;
        border: 1px solid #000000 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #000000 !important;
        width: 100px !important;
    }

    .inputTextArea {
        width: 600px !important;
        height: 66px !important;
        left: 32px !important;
        top: 525px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
    }

    .input {
        width: 600px !important;
        height: 34px !important;
        left: 32px !important;
        top: 90px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        margin-bottom: 20px !important;
        padding-left: 10px !important;
    }

    .label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .ModalBackGroundPanel {
        position: relative !important;
        width: 600px !important;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        max-height: 650px !important;
        padding-top: 0px !important;
        overflow: hidden !important;
    }

    .contctusHeader {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 19px !important;
        line-height: 23px !important;
        letter-spacing: -0.02em !important;
        color: #2D2D2D !important;
    }
</style>
<script>
    import $ from 'jquery';

    export default {
        components: {},
        name: 'ContactUs',
        props: ["userDetails", "userSelection"],
        data: function () {
            return {
                IsEmailSent: false,
                Message: "",

                ruleForm: {
                    givenName: '',
                    surname: '',
                    emailAddress: '',
                    organisationName: '',
                    contactNumber: '',
                    bodyMessage: ''
                },
                rules: {
                    givenName: [
                        { required: true, message: 'Please input First name', trigger: 'change' },
                    ],
                    surname: [
                        { required: true, message: 'Please input Second name', trigger: 'change' }
                    ],
                    emailAddress: [
                        { required: true, message: 'Please input Email address', trigger: 'change' }
                    ],
                    organisationName: [
                        { required: true, message: 'Please input Organisation name', trigger: 'change' }
                    ],
                    contactNumber: [
                        { required: true, message: 'Please input Contact number', trigger: 'change' }
                    ],
                    bodyMessage: [
                        { required: true, message: 'Please input Message', trigger: 'change' }
                    ],
                }
            }
        },
        methods:
        {
            IsDisabled: function () {
                if (this.UserDetails.givenName === null || this.UserDetails.givenName === "")
                    return true;
                else if (this.UserDetails.emailAddress === null || this.UserDetails.emailAddress === "")
                    return true;
                else if (this.UserDetails.organisationName === null || this.UserDetails.organisationName === "")
                    return true;
                else if (this.UserDetails.contactNumber === null || this.UserDetails.contactNumber === "")
                    return true;
                else if (this.UserDetails.bodyMessage === null || this.UserDetails.bodyMessage === "")
                    return true;
                else
                    return false;
            },
            SubmitContactForm: function (formName) {
                var thisControl = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        $.ajax({
                            type: 'POST',
                            url: "/ContactUs/ContactUsSubmit/",
                            contentType: 'application/json',
                            data: JSON.stringify(
                                thisControl.UserDetails
                            ),
                            success: function (data) {
                                thisControl.IsEmailSent = true;
                                thisControl.Message = data.message;
                            },
                            error: function (xhr) {
                                thisControl.$message.error(xhr.responseText);
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });



                //if (thisControl.UserDetails.givenName === null || thisControl.UserDetails.givenName === "") {
                //    this.$message.error('First name can not be empty.');
                //    return false;
                //}
                //else if (thisControl.UserDetails.emailAddress === null || thisControl.UserDetails.emailAddress === "") {
                //    this.$message.error("Email can not be empty");
                //    return false;
                //}
                //else if (thisControl.UserDetails.organisationName === null || thisControl.UserDetails.organisationName === "") {
                //    this.$message.error("Organisation name can not be empty");
                //    return false;
                //}
                //else if (thisControl.UserDetails.contactNumber === null || thisControl.UserDetails.contactNumber === "") {
                //    this.$message.error("Contact number can not be empty");
                //    return false;
                //}
                //else if (thisControl.UserDetails.bodyMessage === null || thisControl.UserDetails.bodyMessage === "") {
                //    this.$message.error('Message can not be empty.');
                //    return false;
                //}
                //else {
                //    $.ajax({
                //        type: 'POST',
                //        url: "/ContactUs/ContactUsSubmit/",
                //        contentType: 'application/json',
                //        data: JSON.stringify(
                //            thisControl.UserDetails
                //        ),
                //        success: function (data) {
                //            thisControl.IsEmailSent = true;
                //            thisControl.Message = data.message;
                //        },
                //        error: function (xhr) {
                //            thisControl.$message.error(xhr.responseText);
                //        }
                //    });
                //}
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        created: function () {
        },
        computed: {
            UserDetails: function () { return this.userDetails },
            UserSelection: function () { return this.userSelection; }
        }

    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

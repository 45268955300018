<!--<template>
    <div class="footer">
        <div class="">
            <p>
                <a style="float:left" href="https://ftrc.co/"> Copyright &#169; 2021 - An <img src="/Images/FTRC-bubbles-and-no-text-and-a-transparent-background-300x75.png" alt="" width="88" height="22"> website</a>
                    <a>
                        An&nbsp;
                        <a href="https://ftrc.co">
                            <img src="/Images/FTRC-bubbles-and-no-text-and-a-transparent-background-300x75.png" alt="" width="88" height="22">
                        </a> website
                    </a>
                    <a style="cursor:pointer;" v-on:click.stop="callPolicyTerms('Privacy');">Privacy Policy</a>
            </p>
        </div>
        <privacy-policy v-if="CurrentSelection === 'Privacy'" v-on:privacypolicy-closed="CurrentSelection = null" />
    </div>
</template>-->
<template>
    <div class="footer">
        <div>
            <p>
                <a class="textColor" href="https://ftrc.co/" target="_blank" rel="noopener noreferrer"> &#169;FTRC  </a>
                <a class="textColor" style="cursor:pointer;" v-on:click.stop="$emit('call-terms')">Terms & Conditions</a>
                <a class="textColor" style="cursor:pointer;" v-on:click.stop="$emit('call-policy')">Privacy Policy</a>
            </p>
        </div>

    </div>
</template>
<style scoped>
    .footer {
        background-color: #E0E0E0 !important;
    }

    .textColor {
        color: #1E228C !important;
    }
</style>
<script>
    //import PrivacyPolicy from "./PrivacyPolicy.vue"

    export default {
        name: 'FooterControl',
        props: ["userDetails"/*, "CurrentSelection"*/],
        data: function () {
            return {
                CurrentSelection: null
            }
        },
        components: {
            //PrivacyPolicy
        },
        methods: {
            callPolicyTerms: function (type) {
                this.track('/' + type);
                this.CurrentSelection = type;
            },
            track: function (page_path) {
                this.$gtag.pageview({
                    'page_path': page_path,
                });
            }
        },
        computed: {
            UserDetails: function () { return this.userDetails;}
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

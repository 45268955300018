<script setup>
import { computed } from 'vue';
import { uniqWith } from 'lodash-es'

const props = defineProps(['decision']);

const extractDescription = (value = {}) => {
    if (value.length) { //is array {
        return value.map(v => v.description).join(" / ");
    } else {
        return value.description;
    }
}

const mapContributor = ({ value, ...c }) => ({
    ...c,
    value: extractDescription(value),
});

const allContributors = computed(() => uniqWith([
    ...props.decision.decisionContributors?.map(c => ({ contributor: c })) ?? [],
    ...props.decision.extraMorbidityContributions ?? [],
    ...props.decision.indicativeExtraMorbidityContributions ?? [],
    ...props.decision.permilleContributions ?? [],
    ...(props.decision.exclusionContributions ?? []).map(i => ({ ...i, exclusion: true })),
    ...(props.decision.indicativeExclusionContributions ?? []).map(i => ({ ...i, exclusion: true })),
    ...(props.decision.evidenceContributions ?? []).map(i => ({ ...i, evidence: true })),
].filter(Boolean)
    .map(mapContributor),
    (val1, val2) => {
        if (val1.contributor.enquiryLine
            && val1.contributor.triggerTag
            && val2.contributor.enquiryLine
            && val2.contributor.triggerTag) {
            return val1.contributor.enquiryLine === val2.contributor.enquiryLine
                && val1.contributor.triggerTag === val2.contributor.triggerTag
        }
        return val1.value === val2.value;
    }
));

const typeMap = {
    "CI": "Critical Illness",
    "TPD": "Total Permanent Disability",
    "WOP": "Waiver of Premium",
    "IP": "Income Protecion",
    "IP_0": "Income Protecion",
    "IP4": "Income Protecion",
    "IP_8": "Income Protecion",
    "ALTCI": "Critical Illness",
    "ALTLIFE": "Life",
    "LIFE_DECREASING": "Life",
    "LIFE_INCREASING": "Life",
}

const decisionMap = {
    "NON_STANDARD": "NON STANDARD",
    "REFER": "REFERED"
}

</script>
<template>
    <div>
        <h3>{{ typeMap[props.decision.componentType] || props.decision.componentType }}</h3>
        <p><b> Decision: </b> {{ decisionMap[decision.type] || decision.type }} </p>
        <div v-for="(c, index) in allContributors" :key="index">

            <div v-if="c.evidence || c.exclusion">
                <p v-if="c.evidence"><b>Further information required: </b>{{ c.value }}</p>
                <p v-if="c.exclusion"><b>Exclusion description: </b>{{ c.value }}</p>
            </div>
            <div v-else-if="c.contributor.triggerTag">
                <p><b> Decision reason {{ index + 1 }}: </b> {{ c.contributor.triggerTag}}.</p>
            </div>
        </div>
        <div v-if="props.decision.disabilityDefinition">
            <p><b>Definition of incapacity: </b>{{ props.decision.disabilityDefinition }}.</p>
        </div>
    </div>
</template>
<template>
    <div>
        <advertisement v-if="advert !== null" :advert="advert" :loading-message="LoadingDescription"
                       :user-selection="UserSelection" screen="Performing Analysis" />
        <loading-control v-if="IsLoading && advert === null" :loading-message="LoadingDescription" />
        <div v-if="UserSelection.externalChanged" class="right">
            <p> Ooops, it looks as though you have changed the {{UserSelection.externalChanged}} within UnderwriteMe. 
                If you would like to run a ProtectionGuruPro comparison on this basis, you will need to restart the analysis. </p>
            <el-button type="primary" @click="GoHome()"> Go back to ProtectionGuruPro </el-button>
        </div>
        <div class="analysisCard" v-if="Analysis !== null && IsLoading === false && !UserSelection.isPending && !UserSelection.externalChanged">

            <div class="left">
                <div class="results">
                    <div class="clientDetails">
                        <div style="display:inline-flex">
                            <div class="labels">
                                Policy details
                            </div>
                            <div class="links" v-if="UserDetails.pricingWebID !== null">
                                <el-link v-on:click.stop="$emit('edit-policy')" class="summaryLabels"
                                         style="text-decoration: underline">Edit</el-link>
                            </div>
                        </div>
                        <div class="detailsItem"
                             v-if="UserSelection.primaryClient !== null && UserSelection.selectedCustomerType != null">
                            <div class="detailsLabel"> Name: </div>
                            <div class="detailsList">{{UserSelection.primaryClient.forename + " " +
                                UserSelection.primaryClient.surname}}</div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.secondaryClient !== null">
                            <div class="detailsLabel"></div>
                            <div class="detailsList">{{UserSelection.secondaryClient.forename + " " +
                                UserSelection.secondaryClient.surname}}</div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.policyTerm !== null">
                            <div class="detailsLabel"> Term length: </div>
                            <div class="detailsList">{{ UserSelection.policyTerm }}</div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.toAge !== null">
                            <div class="detailsLabel"> To Age: </div>
                            <div class="detailsList">{{ UserSelection.toAge }}</div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null">
                            <div class="detailsLabel"> Annual life or earlier CI amount assured: </div>
                            <div class="detailsList">
                                &pound;{{ UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount }}
                            </div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.termBenefitAmount !== null">
                            <div class="detailsLabel"> Annual life cover Amount Assured: </div>
                            <div class="detailsList">&pound;{{ UserSelection.termBenefitAmount }}</div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.termCriticalIllnessCoverAmount !== null">
                            <div class="detailsLabel"> Annual CI cover amount: </div>
                            <div class="detailsList">&pound;{{ UserSelection.termCriticalIllnessCoverAmount }}</div>
                        </div>
                    </div>

                    <div class="customizeResult"
                         :style="UserSelection.ipBenefitBasis === 'Monthly' ? 'top:209px;' : 'top:179px;'">
                        <div class="labels" style="width:251px"> Customize your results </div>
                        <div class="clientBudget">
                            <label class="labels" style="line-height:24px">Client budget</label>
                            <span class="budget">
                                <el-input v-model="UserSelection.clientBudget" type="number" class="input-number" />
                            </span>
                        </div>
                    </div>

                    <div>
                        <el-checkbox v-model="RemoveNotInBudget" label="Remove products not in budget"
                                     class="checkboxLabel "
                                     :disabled="UserSelection.clientBudget === null || UserSelection.clientBudget <= 0"></el-checkbox>
                    </div>

                    <div v-if="Analysis !== null && IsLoading === false" class="premiumTypeDetails" style="margin-top:16px">
                        <div v-if="UserSelection.underwritten">
                            <div class="labels"> Underwriting </div>
                            <el-checkbox-group v-model="AnalysisUMEFilter"> 
                                <!-- <el-checkbox label="STANDARD">Buy now</el-checkbox>
                                <el-checkbox label="NON_STANDARD">Buy now non-standard</el-checkbox> -->
                                <el-checkbox label="EVIDENCE_REQUIRED">Evidence Required</el-checkbox>
                                <el-checkbox label="REFER">Referred</el-checkbox>
                                <el-checkbox label="POSTPONE">Can't offer cover now</el-checkbox>
                                <el-checkbox label="DECLINE">Can't offer cover</el-checkbox>
                            </el-checkbox-group>
                            <el-checkbox-group v-model="AnalysisUMEExclusionFilter"> 
                                <el-checkbox label="EXCLUSION">Exclusion</el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div class="labels"> Premium Type </div>
                        <el-checkbox-group v-if="UserSelection.premiumTypeFilter === null"
                                           v-model="AnalysisPremiumTypeFilter">
                            <el-checkbox value="Guaranteed" label="Guaranteed"></el-checkbox>
                            <el-checkbox value="Reviewable" label="Reviewable"></el-checkbox>
                        </el-checkbox-group>

                        <div v-for="(filter, filterIndex) in Analysis.providerFilters" :key="filterIndex">
                            <div class="labels" style="margin-top:16px;width:100%;"> {{ filter.name }} </div>
                            <div style="margin-top:5px;" :title="filter.description"
                                 v-for="option in filter.providerFilterOptions" :key="option.reference">
                                <el-checkbox v-model="SelectedCustomFilterOptionKeys" :label="option.reference"
                                             :value="option.reference" :id="'option' + option.reference">
                                    <label :for="'option' + option.reference" title="" style="height:0px !important">
                                        {{ option.option }}
                                    </label>
                                </el-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="navigationbtn">
                        <el-button type="success" v-if="Analysis !== null && IsLoading === false"
                                   @click="IsCancel = IsRequestingReport = true"
                                   :disabled="UserDetails.pricingWebID === null || !isValidCustomer">Download Report</el-button>
                        <br />
                        <el-button type="default" v-if="Analysis !== null && IsLoading === false"
                                   @click="IsShowDownloadIllustration = true"
                                   :disabled="UserDetails.pricingWebID === null">Download Illustration</el-button>
                        <br />
                        <el-button type="default" v-if="Analysis !== null && IsLoading === false"
                                   v-on:click.stop="IsApplyNow = true" :disabled="UserDetails.pricingWebID === null">
                            Apply
                            Now
                        </el-button>
                        <br />
                        <el-button type="default"
                                   v-if="UserSelection.expressTemplate !== null && IsLoading === false && UserSelection.legacyPolicyProviderReference === ''"
                                   v-on:click.stop="$emit('amend-quality-analysis')"
                                   :disabled="UserDetails.pricingWebID === null">Amend analysis</el-button>
                    </div>

                    <LeftPanelReferences></LeftPanelReferences>

                    <pricing-report-details v-if="IsLoading === false && IsRequestingReport === true"
                                            :user-selection="UserSelection" :user-details="UserDetails" v-on:generate-report="DownloadPDFReport"
                                            v-on:generate-short-report="DownloadPDFShortReport"
                                            v-on:report-details-cancelled="IsCancel = IsRequestingReport = false" :current-analysis="Analysis"
                                            :chart-data="OverallChartData" :chart-data-headings="MainChartAdditionalHeadings"
                                            :compare-selection="CompareSelection" />

                    <!--Added Download Illustration modal here based on Apply Now Modal-->
                    <download-illustration v-if="IsShowDownloadIllustration === true && IsLoading === false"
                                           v-on:download-illustration="DownloadIllustrations"
                                           v-on:cancelled="IsShowDownloadIllustration = false" :chart-data="OverallChartData"
                                           :chart-data-headings="MainChartAdditionalHeadings" :user-selection="UserSelection" />

                    <apply-now-modal v-if="IsApplyNow === true && IsLoading === false" v-on:apply-now="ApplyNow"
                                     v-on:cancelled="IsApplyNow = false" :chart-data="PurchasableItems"
                                     :chart-data-headings="MainChartAdditionalHeadings" />

                    <analysis-breakdown v-if="ShowAnalysisBreakdown === true" @closed="ShowAnalysisBreakdown = false"
                                        :extended-features="UserSelection.extendedFeatures" :analysis="Analysis"
                                        :provider-selection="SelectedProducts" />

                </div>
            </div>

            <div class="right">
                <div style="display:inline-flex">
                    <div class="count"> {{ MainChartData.items.length }} results</div>
                    <div style="float:right;">
                        <el-radio-group v-model="Filter" size="medium" style="display:inline-flex">
                            <el-radio-button class="radioText" label="All"></el-radio-button>
                            <el-radio-button class="radioText"
                                             :disabled="UserSelection.clientBudget === null || UserSelection.clientBudget <= 0"
                                             label="Best quality in budget"></el-radio-button>
                            <el-radio-button class="radioText"
                                             :disabled="UserSelection.clientBudget === null || UserSelection.clientBudget <= 0"
                                             label="In budget"></el-radio-button>
                            <el-radio-button class="radioText" label="Lowest premium"></el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <br />
                <div>
                    <el-select v-model="CompareSelection" popper-class="SearchProductsPopper"
                               placeholder="Type to search for and/or compare products" class="SearchCustomers" :multiple-limit="3"
                               prefix-icon="el-icon-search" filterable multiple>
                        <el-option-group label="Best in quality">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].BestQuality; })"
                                       :value="cdi.values[0].Reference" :key="'BestQuality' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo" style="width:40px;height:40px;vertical-align:bottom;"
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="Best quality in budget">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].BestInBudget; })"
                                       :value="cdi.values[0].Reference" :key="'BestInBudget' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo" style="width:40px;height:40px;vertical-align:bottom;"
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="Lowest premium">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].LowestPremium; })"
                                       :value="cdi.values[0].Reference" :key="'LowestPremium' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo" style="width:40px;height:40px;vertical-align:bottom;"
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="In budget">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].InBudget; })"
                                       :value="cdi.values[0].Reference" :key="'InBudget' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo" style="width:40px;height:40px;vertical-align:bottom;"
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="All Products">
                            <el-option v-for="(cdi) in MainChartData.items" :value="cdi.values[0].Reference"
                                       :key="cdi.values[0].Reference" style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo" style="width:40px;height:40px;vertical-align:bottom;"
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                    </el-select>
                </div>
                <div v-if="Analysis !== null && IsLoading === false">
                    <div class="orderBy">
                        <div>
                            <label class="allresults">All results (Click on a product to compare in more detail)</label>
                        </div>
                        <div>
                            <el-select style="border:none !important" v-model="IsPricing" label="sort by:"
                                       placeholder="Sort by">
                                <el-option label="Order by quality" :value="false"></el-option>
                                <el-option label="Order by price" :value="true"></el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="AllData">
                        <!--<span>
                            <collapsable-bar-chart max-selection="3" :selection="CompareSelection" :title="IsPricing ? 'Pricing': 'Quality Score'" :chart-data="SelectedMainChartData" :expanded-keys="ExpandedProviders" :alternate-value-title="MainChartAdditionalHeadings" :user-selection="UserSelection" :analysis="Analysis" />
                        </span>-->
                        <span>
                            <collapsable-bar-chart max-selection="3" chart-type="Selected" @compare="PerformComparison"
                                                   :selection="CompareSelection" :title="IsPricing ? 'Pricing' : 'Quality Score'"
                                                   :chart-data="SelectedMainChartData" :expanded-keys="ExpandedProviders"
                                                   :alternate-value-title="MainChartAdditionalHeadings" :user-selection="UserSelection"
                                                   :analysis="Analysis" />
                        </span>
                        <span>
                            <collapsable-bar-chart max-selection="3" chart-type="Unselected" :selection="CompareSelection"
                                                   :title="IsPricing ? 'Pricing' : 'Quality Score'" :chart-data="UnselectedMainChartData"
                                                   :expanded-keys="ExpandedProviders" :alternate-value-title="MainChartAdditionalHeadings"
                                                   :user-selection="UserSelection" :analysis="Analysis" />
                        </span>
                        <!--<div v-if="CompareSelection.length > 0" class="bottomHugging">
                            <button class="compare" @click="PerformComparison">Analyse Selected Products</button>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <confirm-credits v-if="toRunAfterDeducted !== null" @deduct-credits-cancelled="toRunAfterDeducted = null"
                         @deduct-credit-succeeded="toRunAfterDeducted()" :client="userSelection.primaryClient"
                         :customer-ref="userSelection.customerReference" product-type="Term Assurance" :customer-type="customerType"
                         :legacyPolicyProviderReference="UserSelection.legacyPolicyProviderReference"
                         :toolName="UserSelection.tool.toolName" :legacyProductName="UserSelection.legacyPolicyProvider"
                         :analysisReferecnce="Analysis.analysisReferecnce" />
    </div>
</template>
<style>
    .SearchProductsPopper .el-select-dropdown__wrap {
        max-height: 400px;
    }

    .maxWidth .el-select div {
        width: unset !important;
    }

    .el-select__tags {
        display: flex !important;
        top: 40% !important;
    }

    .el-collapse-item__header {
        /*display: flex !important;*/
    }
</style>
<style scoped>
    .el-radio-button__inner:hover {
        color: black;
    }

    span .el-checkbox__inner {
        width: 24px !important;
        height: 24px !important;
    }

    .el-select-dropdown__item.selected {
        color: black;
        font-weight: 700;
    }

    .analysisCard {
        display: flex;
        max-width: 1144px !important;
        min-width: 1144px !important;
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        border-radius: 8px;
        padding: 24px;
    }

    .orderBy {
        justify-content: space-between;
        display: inline-flex;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 775px;
    }

    .card {
        margin-left: 350px;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .btnQuality {
        justify-content: center;
        align-items: center;
        padding: 5px;
        position: static;
        width: max-content !important;
        background: none;
        height: 40px;
        left: 199px;
        top: 0px;
        border-radius: 8px;
    }

    .radioText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 56px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.04em;
        color: #4B5768;
    }

    .monthlyBenefit {
        position: inherit;
        width: 100px;
        left: 422px;
        margin-left: 15px;
    }

    .price {
        position: inherit;
        width: 80px;
        left: 422px;
        margin-left: 15px;
    }

    .qualityScore {
        position: inherit;
        width: 150px;
        left: 200px;
        margin-left: 35px;
    }

    .links {
        text-decoration: underline;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }

    .title {
        position: relative;
        height: 34px;
        left: 150px;
        top: 150px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 41px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #000000;
    }

    .results {
        width: 317px;
        left: 150px;
        top: 129px;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .clientDetails {
        padding: 16px;
        position: relative;
        width: 285px;
        height: 165px;
        left: 0px;
        top: 0px;
        background-color: #F7F8F9;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .labels {
        position: inherit;
        height: 80px;
        position: static;
        width: 150px;
        height: 28px;
        left: 0px;
        top: 0.5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #323A46;
    }

    .links {
        position: static;
        width: 109px;
        height: 28px;
        left: 176px;
        top: 0.5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 41px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
        text-decoration-line: underline;
        color: #323A46;
        margin-left: 85px;
    }

    .detailsLabel {
        width: 129px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        align-items: center;
        color: #A0ABBB;
    }

    .detailsList {
        width: 144px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 8px;
        /* or 57% */
        display: inline-block;
        align-items: center;
        /* Neutral/700 */
        color: #323A46;
        margin-left: 12px
    }


    .customizeResult {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 15px;
        position: static;
        width: 285px;
        left: 16px;
        top: 197px;
    }

    .clientBudget {
        position: static;
        width: 280px;
        left: 0px;
        top: 39px;
    }

    .el-input {
    }

    .checkboxLabel {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 8px;
        align-items: center;
        color: #323A46;
        padding-left: 10px;
        left: 10px;
    }

    .premiumTypeDetails {
        position: static;
        width: 285px;
        height: 173px;
        left: 16px;
        top: 100px;
        padding-left: 15px;
    }

    .navigationbtn {
        margin-top: 100px;
        padding: 16px;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #323A46;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #1E228C !important;
        /*border-color: #059669 !important;*/
        margin-top: 25px !important;
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: absolute;
        height: 40px;
        left: 297px;
        top: 0px;
    }

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 285px !important;
        height: 50px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success:focus,
    .el-button--success:hover {
        background: #1E228C !important;
        border-radius: 8px !important;
        /*border-color: #1E228C !important;*/
    }

    .right {
        padding: 16px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-left: 20px;
    }

    .count {
        position: static;
        width: 330px;
        height: 40px;
        left: 0px;
        top: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 41px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .allresults {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        padding-top: 16px;
        margin-bottom: 10px;
    }

    .SearchCustomers {
        margin-top: 10px;
        border-radius: 8px;
        width: 790px;
        min-height: 48px !important;
        display: inline-block;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
        position: static;
        width: 785px;
        height: 98px;
        left: 16px;
        top: 162px;
        background: #F6F6F6;
        border-radius: 8px;
    }

    .logo {
        position: absolute;
        width: 56px;
        height: 61.25px;
        background: url('https://logo.clearbit.com/spotify.com');
        position: absolute;
    }

    .text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #48494D;
    }

    /* old CSS */
    .analysisbottomMargin50 {
        width: 100% !important;
    }

    .bottomHugging {
        position: sticky;
        bottom: 60px;
        height: 50px;
        background-color: rgba(30, 34, 140, 0.5);
        padding-top: 10px;
    }

    .compare {
        width: 300px;
    }

    .chartDiv {
        padding-left: 330px;
        min-width: auto;
        display: block
    }

        .chartDiv div,
        .chartDiv div div {
            display: block;
            min-width: auto;
        }

    #Quality + label {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    #Pricing + label {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    fieldset {
        text-align: center;
        margin-left: auto;
        width: 425px;
        margin-right: auto;
    }

        fieldset.customise {
            text-align: left;
            border: 3px solid #1e228c;
            padding: 0 0 0 10px;
            width: 300px;
            float: left;
            position: sticky;
            top: 0;
        }

            fieldset.customise legend {
                padding: 5px 5px 5px 5px;
                float: left;
            }

            fieldset.customise h3 {
                padding: 5px 5px 5px 5px;
                clear: both;
                color: #1e228c;
                font-weight: normal;
                border-top: 1px solid #1e228c;
            }

    .budget {
        display: block;
        margin: 5px 5px 5px 5px;
        position: relative;
        padding-left: 25px;
        border: solid 1px black;
    }

        .budget::before {
            content: "£";
            width: 10px;
            font-family: 'Roboto';
            color: #1e228c;
            display: inline-block;
            padding-left: 5px;
            font-size: 24px;
            text-align: left;
            position: absolute;
            left: 0px;
        }

        .budget input {
            border: none;
            font-size: 24px;
            padding: 0px 0px 0px 0px;
            display: block;
            width: 100%;
        }
</style>
<script>
    import AnalysisBreakdown from './AnalysisBreakdown.vue';
    import CollapsableBarChart from './CollapsableBarChart.vue';
    import PricingReportDetails from './PricingReportDetails.vue';
    import LoadingControl from './LoadingControl.vue';
    import $ from 'jquery';
    import helper from './HelperMethods.vue';
    import Advertisement from './Advertisement.vue';
    import ApplyNowModal from './ApplyNowModal.vue';
    import DownloadIllustration from './DownloadIllustration.vue';
    import { ElMessage } from 'element-plus';
    import ConfirmCredits from './ConfirmCredits.vue';
    import LeftPanelReferences from './LeftPanelReferences.vue';
    export default {
        name: 'FamilyIncomeBenefitAnalysis',
        components: { AnalysisBreakdown, CollapsableBarChart, PricingReportDetails, LoadingControl, Advertisement, ApplyNowModal, DownloadIllustration, ConfirmCredits, LeftPanelReferences },
        inject: ["SubscriptionService"],
        props: ["userSelection", "userDetails", "adverts"],
        data: function () {
            return {
                deferredPeriod: ['1 day', '1 Week', '2 Weeks', '1 Month', '2 Month', '3 Month', '6 Month', '12 Month', '24 Months'],
                Filter: 'All',
                IsApplyNow: false,
                IsLoading: false,
                LoadingDescription: null,
                advert: null,
                ShowAnalysisBreakdown: false,
                CompareSelection: [],
                Budget: null,
                //RemoveNotInBudget: false,
                Analysis: null,
                Error: null,
                SelectionAnalysisOption: JSON.stringify({ title: "Analysis", cat: null, feature: null }),
                SelectionOrderingOption: "Grouped_Providers",
                IsRequestingReport: false,
                IsRequestingSummaryReport: false,
                DataTableResponse: null,
                SelectFeatureInAnalysis: null,
                SelectedSubFeatureInAnalysis: null,
                Features: null,
                ExtendedFeatures: null,
                Providers: null,
                IsCancel: true,
                IsPricing: false,
                ExpandedProviders: { "keys": [] },
                AnalysisPremiumTypeFilter: ['Guaranteed', 'Reviewable'],
                AnalysisUMEFilter: ['NON_STANDARD', 'STANDARD', 'REFER', 'UNKNOWN', 'EVIDENCE_REQUIRED', 'POSTPONE', 'DECLINE'],
                AnalysisUMEExclusionFilter: ['EXCLUSION', ''],
                SelectedCustomFilterOptionKeys: [],
                ProductStarFilter: 'All',
                IsShowDownloadIllustration: false,
                customerType: null,
                isValidCustomer: false,
                toRunAfterDeducted: null,
                IsDeductingCredit: false,
                analysisResponse: null,
            }
        },
        methods: {
            DownloadPDFReport: function (request) {
                var analysisUrl = "/report/longreportpdf";
                var thisControl = this;
                thisControl.toRunAfterDeducted = null;
                thisControl.SubscriptionService.hasCredit(thisControl.userSelection.customerReference, "Term Assurance", thisControl.customerType).then(function (hasCredit) {
                    thisControl.UserSelection.includedProviders = thisControl.CompareSelection;
                    thisControl.LoadingDescription = "Just a moment, we are preparing your report...";
                    var pdfToDownload = null;
                    var reportreference = null;
                    var waiting = 1;
                    var done = function () {
                        helper.downloadFile("application/pdf", helper.GetClientsName(thisControl.UserSelection) + ".pdf", pdfToDownload, '/customerreport/' + thisControl.UserSelection.customerReference + '/' + thisControl.Analysis.analysisReferecnce + "/" + reportreference);
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                        thisControl.IsRequestingReport = false; //close download report dialogue...
                    }
                    var adToShow = helper.GetAdvert(thisControl.adverts, "WhenDownloadingReport", thisControl.UserSelection);
                    if (adToShow !== null) {
                        thisControl.advert = adToShow;
                        waiting++;
                        setTimeout(function () {
                            waiting--;
                            if (waiting == 0)
                               done();
                        }, adToShow.minimumDurationInSeconds * 1000);
                    }

                    if (hasCredit || (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '')) {
                        thisControl.IsLoading = true;
                        $.ajax({
                            type: 'POST',
                            url: analysisUrl,
                            contentType: 'application/json',
                            data: JSON.stringify(request),
                            success: function (pdf, _, request) {
                                pdfToDownload = pdf;
                                reportreference = request.getResponseHeader("ReportReference");
                                waiting--;
                                if (waiting == 0)
                                    done();
                            },
                            error: function (xhr) {
                                if (xhr.status == 401) {
                                    window.location = "./Api/signin";
                                    return;
                                }
                                waiting--;
                                if (waiting == 0)
                                    done();
                                //alert("Problem Getting Report" + xhr.responseText);
                            }
                        });
                    }
                    else {
                        //Need to prompt user for a credit;
                        thisControl.toRunAfterDeducted = function () {
                            thisControl.IsLoading = true;
                            thisControl.toRunAfterDeducted = null;
                            $.ajax({
                                type: 'POST',
                                url: analysisUrl,
                                contentType: 'application/json',
                                data: JSON.stringify(request),
                                success: function (pdf, _, request) {
                                    pdfToDownload = pdf;
                                    reportreference = request.getResponseHeader("ReportReference");
                                    waiting--;
                                    if (waiting == 0) {
                                        done();
                                        if (pdfToDownload != undefined || pdfToDownload != null || pdfToDownload != "") {
                                            thisControl.SubscriptionService.update();
                                            ElMessage({
                                                message: "Credit Deducted",
                                                type: 'success'
                                            });
                                        }
                                    }
                                },
                                error: function (xhr) {
                                    if (xhr.status == 401) {
                                        window.location = "./Api/signin";
                                        return;
                                    }
                                    waiting--;
                                    if (waiting == 0)
                                        done();
                                    //alert("Problem Getting Report" + xhr.responseText);
                                }
                            });
                        }
                    }
                });
            },
            DownloadPDFShortReport: function (request) {
                var analysisUrl = "/report/shortreportpdf";
                var thisControl = this;
                thisControl.toRunAfterDeducted = null;
                thisControl.SubscriptionService.hasCredit(thisControl.userSelection.customerReference, "Term Assurance", thisControl.customerType).then(
                    function (hasCredit) {
                        thisControl.LoadingDescription = "Just a moment, we are preparing your report...";
                        var pdfToDownload = null;
                        var reportreference = null;
                        var waiting = 1;
                        var done = function () {
                            helper.downloadFile("application/pdf", helper.GetClientsName(thisControl.UserSelection) + ".pdf", pdfToDownload, '/customerreport/' + thisControl.UserSelection.customerReference + '/' + thisControl.Analysis.analysisReferecnce + "/" + reportreference);
                            thisControl.IsLoading = false;
                            thisControl.advert = null;
                            thisControl.IsRequestingReport = false; //close download report dialogue...
                        };
                        var adToShow = helper.GetAdvert(thisControl.adverts, "WhenDownloadingReport", thisControl.UserSelection);
                        if (adToShow !== null) {
                            thisControl.advert = adToShow;
                            waiting++;
                            setTimeout(function () {
                                waiting--;
                                if (waiting == 0)
                                    done();
                            }, adToShow.minimumDurationInSeconds * 1000);
                        }
                        if (hasCredit || (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '')) {
                            thisControl.IsLoading = true;
                            $.ajax({
                                type: 'POST',
                                url: analysisUrl,
                                contentType: 'application/json',
                                data: JSON.stringify(request),
                                success: function (pdf, _, request) {
                                    pdfToDownload = pdf;
                                    reportreference = request.getResponseHeader("ReportReference");
                                    waiting--;
                                    if (waiting == 0)
                                        done();
                                },
                                error: function (xhr) {
                                    thisControl.IsLoading = false;
                                    if (xhr.status == 401) {
                                        window.location = "./Api/signin";
                                        return;
                                    }
                                    waiting--;
                                    if (waiting == 0)
                                        done();
                                    thisControl.$message.error("Problem Getting Report" + xhr.responseText);
                                }
                            });
                        }
                        else {
                            thisControl.toRunAfterDeducted = function () {
                                thisControl.IsLoading = true;
                                thisControl.toRunAfterDeducted = null;
                                $.ajax({
                                    type: 'POST',
                                    url: analysisUrl,
                                    contentType: 'application/json',
                                    data: JSON.stringify(request),
                                    success: function (pdf, _, request) {
                                        pdfToDownload = pdf;
                                        reportreference = request.getResponseHeader("ReportReference");
                                        waiting--;
                                        if (waiting == 0) {
                                            done();
                                            if (pdfToDownload != undefined || pdfToDownload != null || pdfToDownload != "") {
                                                thisControl.SubscriptionService.update();
                                                ElMessage({
                                                    message: "Credit Deducted",
                                                    type: 'success'
                                                });
                                            }
                                        }
                                    },
                                    error: function (xhr) {
                                        thisControl.IsLoading = false;
                                        if (xhr.status == 401) {
                                            window.location = "./Api/signin";
                                            return;
                                        }
                                        waiting--;
                                        if (waiting == 0)
                                            done();
                                        thisControl.$message.error("Problem Getting Report" + xhr.responseText);
                                    }
                                });
                            }
                        }
                    }
                );
            },
            ReportDetails: function () {
                var thisControl = this;
                thisControl.UserSelection.includedProviders = thisControl.CompareSelection;

                thisControl.UserSelection.reportTitle = thisControl.ReportTitle;
                thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 }).forEach(function (feature) {
                    if (thisControl.UserSelection.includeFeatureInDataTable !== undefined && thisControl.UserSelection.includeFeatureInDataTable.length > 0 && thisControl.UserSelection.includeFeatureInDataTable.includes(feature.featureReference))
                        feature.includeInDataTable = true;
                });

                thisControl.UserSelection.categoryWeightings.filter(function (category) { return category.weight > 0 }).forEach(function (category) {
                    if (thisControl.UserSelection.includeFeatureInDataTable !== undefined && thisControl.UserSelection.includeCategoryInDataTable.length > 0 && thisControl.UserSelection.includeCategoryInDataTable.includes(category.category)) {
                        thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 && feature.featureCategory === category.category }).forEach(function (feature) {
                            feature.includeInDataTable = true;
                        });
                    }
                });

                return {
                    selection: thisControl.UserSelection,
                    analysis: thisControl.Analysis,
                    //firstInsuredName: thisControl.UserSelection.primaryPersonName,
                    //secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryPersonName,
                    firstInsuredName: thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    providersInDataTables: thisControl.HasDataTables ? thisControl.UserSelection.includedProviders : null,
                    reportTitle: "Test",
                    ShouldIncludeDefinitions: thisControl.ShouldIncludeDefinitions
                };
            },
            PerformComparison: function () {
                var comparisonUrl = "/api/Journey/Comparison";
                var thisControl = this;
                var request = thisControl.ReportDetails();
                thisControl.IsComparing = true;
                $.ajax({
                    type: 'POST',
                    url: comparisonUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function () {
                        thisControl.IsComparing = false;
                        thisControl.ShowAnalysisBreakdown = true;
                    },
                    error: function (xhr) {
                        thisControl.IsComparing = false;
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //alert("Problem Getting Report" + xhr.responseText);
                    }
                });

            },
            ApplyNow: function (id) {
                var previousSelected = this.CompareSelection;
                var waiting = 2;
                this.IsLoading = true;
                this.LoadingDescription = "Just a moment, we will redirect you to insurer...";
                this.CompareSelection = [id];
                var applyNowUrl = null;
                var thisControl = this;
                var request = thisControl.ReportDetails();
                var done = function () {
                    window.open(applyNowUrl);
                    thisControl.CompareSelection = previousSelected;
                    thisControl.IsLoading = false;
                    thisControl.IsApplyNow = false;
                }
                thisControl.IsComparing = true;
                $.ajax({ //log mi
                    type: 'POST',
                    url: "/api/Journey/ApplyNow",
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function () {
                        waiting--;
                        if (waiting == 0)
                            done();
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        waiting--;
                        if (waiting == 0)
                            done();
                        thisControl.$message.error("Problem logging apply now: " + xhr.responseText);
                    }
                });

                // todo: We will need to change it when we add a new pricing integration, saving the integration value into the Analysis
                var serviceType = "IPIPELINE";
                if (thisControl.UserSelection.externalApplicationId != null)
                {
                    serviceType = "UNDERWRITEME"
                    id = thisControl.UserSelection.availableFIBProducts.filter(function(p) {return p.quoteID == id})[0].productID;
                }
                // end-todo

                $.ajax({ //get redirect uri
                    type: 'GET',
                    url: '/api/pricing/ApplyLink/' + serviceType + "/" + id + "/" + thisControl.UserSelection.externalApplicationId,
                    contentType: 'application/json',
                    success: function (data) {
                        applyNowUrl = data;
                        waiting--;
                        if (waiting == 0)
                            done();
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        else if (xhr.status == 422) {
                            thisControl.$message.error(xhr.responseText)
                            thisControl.CompareSelection = previousSelected;
                            thisControl.IsLoading = false;
                        }
                        else
                        {
                            waiting--;
                            if (waiting == 0)
                                done();
                            thisControl.$message.error("Problem redirecting: " + xhr.responseText);
                        }
                    }
                });
            },
            DownloadIllustrations: function (id) {
                var previousSelected = this.CompareSelection;
                var waiting = 1;
                this.IsLoading = true;
                this.LoadingDescription = "Just a moment, we will getting your illustrations...";
                var thisControl = this;
                this.CompareSelection = [id];
                var request = thisControl.ReportDetails();
                var illlustrationmessages = [];
                var downloadNowUrl = null;
                var done = function () {
                    if (thisControl.UserSelection.externalApplicationId != null && thisControl.UserSelection.externalApplicationId.includes("UME")) {
                        helper.downloadFile("application/zip", helper.GetClientsName(thisControl.UserSelection) + ".pdf", downloadNowUrl, null);
                    }
                    else {
                    helper.downloadFile("application/zip", helper.GetClientsName(thisControl.UserSelection) + ".zip", downloadNowUrl, null);
                    }
                    thisControl.CompareSelection = previousSelected;
                    thisControl.IsLoading = false;
                    thisControl.IsShowDownloadIllustration = false;
                    for (var imindex = 0; imindex < illlustrationmessages.length; imindex++) {
                        var im = illlustrationmessages[imindex];
                        ElMessage({
                            message: im,
                            type: 'error',
                        })
                    }
                }
                thisControl.IsComparing = true;

                $.ajax({
                    type: 'POST',
                    url: '/api/illustration/Illustrations/' + id,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (data, _, xhr) {
                        downloadNowUrl = data;
                        illlustrationmessages = JSON.parse(xhr.getResponseHeader("illustrationmessages"));
                        waiting--;
                        if (waiting == 0)
                            done();
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        waiting--;
                        if (waiting == 0)
                            done();
                        thisControl.$message.error("Problem redirecting: " + xhr.responseText);
                    }
                });
            },
            AddLegacyProductToCompareSelection: function () {
                var thisControl = this;
                var legacyQuoteID = thisControl.FilteredProducts.filter(function (p) { return p.productID === thisControl.UserSelection.legacyPolicyProviderReference; })[0].quoteID;
                if (legacyQuoteID !== null && legacyQuoteID !== undefined && !thisControl.CompareSelection.includes(legacyQuoteID))
                    thisControl.CompareSelection.push(legacyQuoteID);
            },
            GoHome: function () { window.location.href = "/";}
        },
        created: function () {
            this.UserSelection.productsInFocus = [];
        },
        watch: {
            FilterProductsInBudget: {
                deep: true,
                handler: function (newValue) {
                    var budget = this.UserSelection.clientBudget === "" ? null : this.UserSelection.clientBudget;
                    var thisControl = this;
                    if (newValue !== null) {
                        this.UserSelection.filteredQuotes = newValue.map(function (p) {
                            return {
                                errors: p.errors !== null ? p.errors : '',
                                price: p.price,
                                productID: p.productID,
                                provider: p.provider,
                                displayName: p.displayName,
                                reference: p.quoteID,
                                benefitType: p.benefitType,
                                premiumType: p.premiumType,
                                apiProductId: p.apiProductId,
                                decisionType: p.decisionType
                            };
                        });
                        var premiumReasonMap =
                        {
                            'Guaranteed': 'Guaranteed premium',
                            'Reviewable': 'Reviewable premium',
                        };
                        var decisionMap =
                        {
                            'NON_STANDARD': 'Buy now non-standard',
                            'EVIDENCE_REQUIRED': 'Evidence Required',
                            'REFER': 'Referred',
                            'POSTPONE': "Can't offer cover now",
                            'DECLINE': "Can't offer cover",
                            'STANDARD': 'Buy now',
                        };
                        this.UserSelection.unavailableProducts =
                            helper.mergeArrays([
                                //Unavailable products from iPipeline.
                                this.UserSelection.highLevelUnavailableProducts,
                                //Products With Errors.
                                this.UserSelection.availableFIBProducts.filter(function (p) { return p.errors != null && p.errors.length > 0; }).map(function (av) {
                                    return {
                                        displayName: av.displayName,
                                        productID: av.productID,
                                        reasons: av.errors,
                                        provider: av.provider,
                                        apiProductId: av.apiProductId,
                                    };
                                }),
                                //Products Filtered Out
                                this.AllProducts.map(function (p) {
                                    {
                                        return {
                                            reasons: helper.mergeArrays([
                                                [
                                                    thisControl.AnalysisPremiumTypeFilter.includes(p.premiumType) ? null : premiumReasonMap[p.premiumType],
                                                    thisControl.RemoveNotInBudget && budget !== null && budget < p.price ? "Not in budget" : null,
                                                    thisControl.AnalysisUMEFilter.includes(p.decisionType) ? null : decisionMap[p.decisionType]
                                                ].filter(function (a) { return a !== null; }),
                                                helper.mergeArrays(thisControl.Analysis.providerFilters.map(function (f) {
                                                    return f.providerFilterOptions
                                                        .map(
                                                            function (fo) {
                                                                var providerFilters = p.providerFilters ?? thisControl.ProviderLookup[p.productID].providerFilters;
                                                                return thisControl.SelectedCustomFilterOptionKeys
                                                                    .indexOf(fo.reference) == -1
                                                                    && providerFilters !== null
                                                                    && providerFilters !== undefined
                                                                    && providerFilters[f.providerFilterReference]
                                                                        .includes(fo.reference) ? fo.option : null;
                                                            }
                                                        )
                                                        .filter(function (fo) { return fo != null; })
                                                }))
                                            ]),
                                            price: p.price,
                                            productID: p.productID,
                                            provider: p.provider,
                                            displayName: p.displayName,
                                            reference: p.quoteID,
                                            isFiltered: true,
                                            apiProductId: p.apiProductId,
                                        }
                                    }
                                }).filter(function (a) { return a.reasons.length > 0; })

                            ]);
                        if (this.UserDetails.isAdminUser) {
                            console.log(this.UserSelection.filteredQuotes.map(function (a) { return a.productID + ": " + a.displayName }).join("\n"));
                        }
                    }
                }
            },
            DisplayableProductFilters: {
                deep: true,
                handler: function (newvalue) {
                    this.UserSelection.productFilters = newvalue;
                }
            },
            MainChartData: {
                deep: true,
                handler: function (val) {
                    if (val == null)
                        return;

                    var newSelection = this.CompareSelection.filter(function (s) {
                        return val.items.some(function (g) { return g.values.some(function (v) { return v.Reference === s; }); })
                    });
                    if (newSelection.length < this.CompareSelection.length)
                        this.CompareSelection = newSelection;

                    newSelection = this.UserSelection.includedProviders.filter(function (s) {
                        return val.items.some(function (g) { return g.values.some(function (v) { return v.Reference === s; }); })
                    });
                    if (newSelection.length < this.UserSelection.includedProviders.length)
                        this.UserSelection.includedProviders = newSelection;

                    if (this.UserSelection.legacyPolicyProviderReference) {
                        this.AddLegacyProductToCompareSelection();
                    }

                    this.UserSelection.productsInFocus = this.CompareSelection;
                }
            },
            SelectFeatureInAnalysis: function (val, oldVal) {
                var thisControl = this;
                if (val === oldVal)
                    return;
                if (thisControl.Analysis === null)
                    return;
                if (val === null) {
                    thisControl.SelectedSubFeatureInAnalysis = null;
                    return
                }
                var featureAnalysis = thisControl.Analysis.featureAnalysis[val];
                if (featureAnalysis !== null && featureAnalysis.subFeatureAnalysis !== null && featureAnalysis.subFeatureAnalysis.length > 0)
                    thisControl.SelectedSubFeatureInAnalysis = featureAnalysis.subFeatureAnalysis[0].subFeatureReference; //select the first subfeature...

            },
            ProductStarFilter: function () {
                if (this.MainChartData !== null) {
                    if (this.ProductStarFilter === 'Best quality in budget') {
                        this.MainChartData = null;

                        //this.MainChartData.items.filter(function (itm) { if (itm.values) return values }).filter(function (val) { return val[0] }).values[0].DisplayValues[3].BestInBudget
                        //this.MainChartData.items[0].values[0].DisplayValues[3].BestInBudget
                    }
                    //else if (this.ProductStarFilter === 'In budget') {

                    //}
                    //else if (this.ProductStarFilter === 'Lowest premium') {

                    //}
                    //else { }
                }
            },
            "UserSelection.clientBudget": function (val) {
                if (isFinite(val))
                    if (val * 1 > 0) {
                        //there is a budget so all no action required
                        return;
                    }
                //there is no budget so check filters
                if (this.Filter === 'In budget' || this.Filter === 'Best quality in budget')
                    this.Filter = 'All';
            },
            "IsRequestingReport": function (val) {
                if (this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '' && val === true && this.CompareSelection.length > 2) {
                    this.CompareSelection = [];
                    this.AddLegacyProductToCompareSelection();
                }
            }
        },
        computed: {
            UserSelection: function () { return this.analysisResponse?.userSelection || this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            RemoveNotInBudget: {
                get: function () {
                    return this.Filter === 'In budget' || this.Filter === "Best quality in budget";
                },
                set: function (value) {
                    if (value)
                        this.Filter = 'In budget';
                    else
                        this.Filter = 'All';
                }
            },
            DisplayableProductFilters: function () {
                if (this.Analysis === null || this.Analysis.providerFilters == undefined)
                    return null;
                var thisControl = this;
                var premiumMap = [
                    { key: 'Guaranteed', value: 'Plans with guaranteed premiums' },
                    { key: 'Reviewable', value: 'Plans with reviewable premiums' }
                ];
                var decisionMap = [
                    { key: 'EVIDENCE_REQUIRED', value: 'Evidence Required' },
                    { key: 'REFER', value: 'Referred' },
                    { key: 'POSTPONE', value: 'Can not offer cover now' },
                    { key: 'DECLINE', value: 'Can not offer' }
                ];

                var result = premiumMap
                    .filter(function (p) { return thisControl.AnalysisPremiumTypeFilter.indexOf(p.key) === -1; })
                    .map(function (p) { return p.value; });
                result = helper.mergeArrays(
                    [
                        result,
                        helper.mergeArrays(
                            this.Analysis.providerFilters
                                .map(function (o) {
                                    return o.providerFilterOptions.filter(function (f) { return thisControl.SelectedCustomFilterOptionKeys.indexOf(f.reference) == -1 }).map(function (f) { return f.option; });
                                })
                        ),
                        decisionMap
                            .filter(function (p) { return thisControl.AnalysisUMEFilter.indexOf(p.key) === -1; })
                        .map(function (p) { return p.value; })

                    ]);
                if (thisControl.RemoveNotInBudget && this.UserSelection.clientBudget !== null) {
                    result.push("Plans not in budget");
                }
                return result;
            },
            SelectedProducts: function () {
                var thisControl = this;

                return this.CompareSelection.map(function (id) {
                    return thisControl.UserSelection.availableFIBProducts.filter(function (p) { return p.quoteID === id })[0];
                })

            },
            MainChartAdditionalHeadings: function () {

                return [['Quality', 'Score'], ['Price'], ['Product', 'Key']];
            },
            AllProducts: function () {
                var thisControl = this;
                return thisControl.UserSelection.availableFIBProducts.filter(function (p) { return p.errors === null || p.errors.length === 0; }).filter(function (ap) {
                    return thisControl.UserSelection.selectedQuotes.some(function (sq) { return sq === ap.quoteID; });
                });
            },
            FilteredProducts: function () {
                if (this.ProviderLookup === null)
                    return null;

                var thisControl = this;
                var products = this.AllProducts;

                if (this.AnalysisPremiumTypeFilter.length > 0)
                    products = products.filter(function (p) { return thisControl.AnalysisPremiumTypeFilter.includes(p.premiumType) });

                if (this.UserSelection.underwritten && this.AnalysisUMEFilter !== null)
                    products = products.filter(function (p) {
                        return thisControl.AnalysisUMEFilter.includes(p.decisionType);
                });
                if (this.UserSelection.underwritten && this.AnalysisUMEExclusionFilter != null)
                     products = products.filter(function (p) {
                         return thisControl.AnalysisUMEExclusionFilter.includes(p.indicativeLoadingContributors != null && p.indicativeLoadingContributors.some(d => !!d.indicativeExclusionContributions || !!d.exclusionContributions) ? "EXCLUSION" : '');
                 });

                products = helper.ApplyProductFilters(
                    products.filter(function (p) { return p.productID !== thisControl.UserSelection.legacyPolicyProviderReference }),
                    function (p) { return p.providerFilters ? p : thisControl.ProviderLookup[p.apiProductId]; },
                    thisControl.Analysis.providerFilters,
                    thisControl.SelectedCustomFilterOptionKeys,
                );

                //Added the legacy products from all prodcuts
                if (thisControl.userSelection.legacyPolicyProviderReference !== null && thisControl.userSelection.legacyPolicyProviderReference !== '')
                    products.push(this.AllProducts.filter(function (p) { return p.productID === thisControl.userSelection.legacyPolicyProviderReference; })[0]);

                return products;
            },
            FilterProductsInBudget: function () {
                if (this.FilteredProducts === null)
                    return null;
                var thisControl = this;
                var budget = this.UserSelection.clientBudget === "" ? null : this.UserSelection.clientBudget;
                var prods = this.FilteredProducts;
                const isLegacy = (p) => p.productID === thisControl.userSelection.legacyPolicyProviderReference;
                switch (this.Filter) {
                    case "All":
                        return prods;
                    //break;
                    case "In budget":
                        return prods.filter(function (p) { return !thisControl.RemoveNotInBudget || budget === null || isLegacy(p) || (budget >= p.price && p.price > 0); })
                    //break;
                    case "Best quality in budget":
                        var scoresInBudget = this.FilteredProducts
                            .filter(function (p) {
                                return budget !== null && budget >= (1 * p.price) && p.price > 0;
                            })
                            .map(function (p) {
                                return parseFloat(thisControl.Analysis.providerScores[p.apiProductId]);
                            });
                        var bestQualityInBudget = Math.max(...scoresInBudget);
                        return prods.filter(function (p) { return budget === null || isLegacy(p) || (budget >= p.price && p.price > 0 && bestQualityInBudget === thisControl.Analysis.providerScores[p.apiProductId]); });
                    //break;
                    case "Lowest premium":
                        var prices = prods.map(function (p) { return p.price; });
                        var minPrice = Math.min(...prices.filter(x=> x > 0));
                        return prods.filter(function (p) { return isLegacy(p) || minPrice === p.price });
                    //break;
                }
                return this.FilteredProducts.filter(function (p) { return !thisControl.RemoveNotInBudget || budget === null || isLegacy(p) || (budget >= p.price && p.price > 0); })
            },
            ProviderLookup: function () {
                if (this.Analysis === null)
                    return null;
                if (this.Analysis.providers === null || this.Analysis.providers == undefined)
                    return null;
                return this.Analysis.providers.reduce(
                    function (rv, x) {
                        var v = x.providerReference;
                        rv[v] = x;
                        return rv;
                    }, {});
            },
            MainChartData: function () {
                var base = this.Analysis;
                if (base === null)
                    return null;
                var thisControl = this;
                var products = thisControl.FilteredProducts;
                if (products == null)
                    return null;
                var budget = thisControl.UserSelection.clientBudget === "" ? null : thisControl.UserSelection.clientBudget * 1;
                var prices = products.map(function (p) { return p.price; });
                var scores = products.filter(function (p) { return p.price > 0 }).map(function (p) { return parseFloat(base.providerScores[p.apiProductId]); });
                var scoresInBudget = products.filter(function (p) { return p.errors !== null && p.errors.length === 0 && p.price <= budget && p.price > 0}).map(function (p) { return parseFloat(base.providerScores[p.apiProductId]); });
                var minPrice = Math.min(...prices.filter(x=> x > 0));
                var bestQuality = Math.max(...scores);
                var bestQualityInBudget = Math.max(...scoresInBudget);

                var baseArray = thisControl.FilterProductsInBudget.map(function (a) { return a; });

                if (this.IsPricing) {

                    baseArray = baseArray.sort(function (a, b) { return a.price - b.price; });

                }
                else {
                    baseArray = baseArray.sort(function (a, b) {  return !a.price || !b.price ? - 1 :  base.providerScores[b.apiProductId] - base.providerScores[a.apiProductId]; });
                }

                var data = baseArray.map(function (p) {
                    return {
                        Label: p.displayName,
                        Logo: '/logos/' + (p.productID === thisControl.userSelection.legacyPolicyProviderReference ? "legacy" : p.provider) + '.png',
                        Tooltip: ((p.warnings !== undefined ? (p.warnings.length > 0 ? "Warnings:\n" + p.warnings.join("\n") : "\n") : "") + (p.errors !== undefined && p.errors !== null ? (p.errors.length > 0 ? "Errors:\n" + p.errors.join("\n") : "\n") : "")).trim(),
                        Value: parseFloat(base.providerScores[p.apiProductId]).toFixed(2) * 1,
                        Color: (p.productID === thisControl.userSelection.legacyPolicyProviderReference ? "#000000" : thisControl.ProviderLookup[p.apiProductId].providerColor),
                        Reference: p.quoteID,
                        Group: p.provider,
                        DisplayValue: "", //parseFloat(base.providerScores[p.productID]).toFixed(0),
                        HasTooltip: p.warnings.length === 0 || p.warnings.length > 0,
                        AlternateDisplayValue: (p.errors !== undefined && p.errors !== null ? (p.errors.length === 0 ? "\u00A3" + (p.price === 0 ? " -" : (1 * p.price).toFixed(2)) : "") : ""),
                        DisplayValues:
                            [
                                parseFloat(base.providerScores[p.apiProductId]).toFixed(1),
                                "\u00A3" + (p.price === 0 ? " -" : (1 * p.price).toFixed(2)),
                                {
                                    BestQuality: bestQuality === parseFloat(base.providerScores[p.apiProductId]),
                                    BestInBudget: budget !== null && (budget >= (1 * p.price) && p.price > 0) && bestQualityInBudget === parseFloat(base.providerScores[p.apiProductId]),
                                    InBudget: budget !== null && budget >= (1 * p.price) && p.price > 0,
                                    LowestPremium: minPrice === 1 * p.price,
                                    IsLegacy: p.productID === thisControl.userSelection.legacyPolicyProviderReference ? true : false,                                
                                    Standard: p.decisionType === "STANDARD" && p.price > 0 ? true : false,
                                    NonStandard: p.decisionType === "NON_STANDARD"? true : false,
                                    Refer: p.decisionType === "REFER"? true : false,
                                    Decline: p.decisionType === "DECLINE" ? true : false,
                                    Postpone: p.decisionType === "POSTPONE" ? true : false,
                                    EvidenceRequired: p.decisionType === "EVIDENCE_REQUIRED" ? true : false,
                                    Exclusion: p.indicativeLoadingContributors != null && p.indicativeLoadingContributors.some(d => !!d.indicativeExclusionContributions || !!d.exclusionContributions)
                                },
                                "\u00A3" + (p.price === 0 ? " -" : (1 * p.priceTo).toFixed(2))
                            ],
                        IndicativeLoadingContributors: p.indicativeLoadingContributors,
                        //Customer: p.customer,
                        KeyFactsLink: p.keyFactsLink,
                        TermsAndConditionsLink: p.termsAndConditionsLink,
                        outlineBoxColor: null,
                        datatableBoxColor: null,
                        tableData: thisControl.UserSelection.categoryWeightings
                            .filter(function (cat) {
                                return cat.weight > 0
                            })
                            .map(function (cat) {
                                return {
                                    category: cat.category,
                                    score: thisControl.Analysis.categoryAnalysis[cat.category].providerScores[p.apiProductId].toFixed(0) * 1
                                };
                            }),
                        IsLegacy: p.productID === thisControl.userSelection.legacyPolicyProviderReference
                    }
                });

                return {
                    showLegend: false,
                    max: 100.0,
                    items: helper.groupByArray(data, "Reference")
                };
            },
            OverallChartData: function () {

                var base = this.Analysis;
                if (base === null)
                    return null;
                var thisControl = this;
                var products = thisControl.FilteredProducts;
                var budget = this.UserSelection.clientBudget === "" ? null : this.UserSelection.clientBudget;
                var prices = products.map(function (p) { return p.price; });
                var scores = products.filter(function (p) { return p.price > 0 }).map(function (p) { return parseFloat(base.providerScores[p.apiProductId]); });
                var scoresInBudget = thisControl.FilterProductsInBudget
                    .map(function (p) {
                        return parseFloat(base.providerScores[p.apiProductId]);
                    });
                var minPrice = Math.min(...prices.filter(x=> x > 0));
                var bestQuality = Math.max(...scores);
                var bestQualityInBudget = Math.max(...scoresInBudget);

                var dataFilteredInBudget = thisControl.FilterProductsInBudget;

                if (this.IsPricing) {
                    dataFilteredInBudget = dataFilteredInBudget.sort(function (a, b) { return a.price - b.price; });
                }                
                else {
                    dataFilteredInBudget = dataFilteredInBudget.sort(function (a, b) { return !a.price || !b.price ? - 1 :  base.providerScores[b.apiProductId] - base.providerScores[a.apiProductId]; });
                }
                
                var data = dataFilteredInBudget.map(function (p) {
                    return {
                        Label: p.displayName,
                        Tooltip: ((p.warnings.length > 0 ? "Warnings:\n" + p.warnings.join("\n") : "\n") + (p.errors != null && p.errors.length > 0 ? "Errors:\n" + p.errors.join("\n") : "\n")).trim(),
                        Value: parseFloat(base.providerScores[p.apiProductId]).toFixed(2) * 1,
                        Color: thisControl.ProviderLookup[p.apiProductId].providerColor,
                        // todo: We will need to change it when we add a new pricing integration, saving the integration value into the Analysis
                        Reference: p.quoteID,
                        Logo: '/logos/' + (p.productID === thisControl.userSelection.legacyPolicyProviderReference ? "legacy" : p.provider) + '.png',
                        Group: p.provider,
                        DisplayValue: '',
                        HasTooltip: p.warnings.length > 0,
                        tableData: [],
                        DisplayValues:
                            [
                                parseFloat(base.providerScores[p.apiProductId]).toFixed(0),
                                "\u00A3" + (1 * p.price).toFixed(2),
                                {
                                    BestQuality: bestQuality === parseFloat(base.providerScores[p.apiProductId]),
                                    BestInBudget: budget !== null && (budget >= (1 * p.price) && p.price > 0) && bestQualityInBudget === parseFloat(base.providerScores[p.apiProductId]),
                                    InBudget: budget !== null && budget >= (1 * p.price) && p.price > 0,
                                    LowestPremium: minPrice === 1 * p.price,
                                    Standard: p.decisionType === "STANDARD" && p.price > 0 ? true : false,
                                    NonStandard: p.decisionType === "NON_STANDARD"? true : false,
                                    Refer: p.decisionType === "REFER"? true : false,
                                    Decline: p.decisionType === "DECLINE" ? true : false,
                                    Postpone: p.decisionType === "POSTPONE" ? true : false,
                                    EvidenceRequired: p.decisionType === "EVIDENCE_REQUIRED" ? true : false,
                                    Exclusion: p.indicativeLoadingContributors != null && p.indicativeLoadingContributors.some(d => !!d.indicativeExclusionContributions || !!d.exclusionContributions)
                                }
                            ],
                        outlineBoxColor: null,
                        datatableBoxColor: null,
                        IsLegacy: p.productID === thisControl.userSelection.legacyPolicyProviderReference,
                        Purchasable: p.purchasable
                    }
                });

                data = data.sort(function (a, b) { return !a.price || !b.price ? - 1 : b.Value - a.Value; });

                return {
                    showLegend: false,
                    max: 100.0,
                    items: helper.groupByArray(data, "Reference")
                };
            },
            PurchasableItems: function () { return { items: helper.groupByArray(this.OverallChartData.items.map(x=> x.values).map(x=> x[0]).filter(x=> x.Purchasable != false), "Reference")}; },
            SelectedMainChartData: function () {
                var result = this.MainChartData;
                var thisControl = this;
                if (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '')
                    thisControl.AddLegacyProductToCompareSelection();

                return {
                    showLegend: result.showLegend,
                    max: result.max,
                    items: result.items
                        .filter(function (a) { return thisControl.CompareSelection.indexOf(a.values[0].Reference) >= 0 })
                        .sort(function (a) { return a.values[0]?.IsLegacy ? -1 : 1 })
                };
            },
            UnselectedMainChartData: function () {
                var result = this.MainChartData;
                var thisControl = this;

                return {
                    showLegend: result.showLegend,
                    max: result.max,
                    items: result.items.filter(function (a) { return thisControl.CompareSelection.indexOf(a.values[0].Reference) < 0 })
                };
            },

            WeightChartTitle: function () {
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection.cat !== null) {
                    return "Feature Breakdown";
                }
                return "Category Breakdown";
            },
            HasWeightChart: function () {
                if (this.IsPricing) return false;
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection.cat !== null) {
                    var category = this.Analysis.categoryAnalysis[selection.cat];
                    if (selection.feature !== null) {
                        var feature = category.featureAnalysis[selection.feature];
                        return (typeof feature.subFeatureAnalysis) !== "undefined" && feature.subFeatureAnalysis !== null && feature.subFeatureAnalysis.length > 0;
                    }
                    return true; //category analysis always has a weight graph
                }
                return true; //overall always has a weight graph
            },
            WeightChartData: function () {
                var data;
                var thisControl = this;
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection.cat !== null) {
                    var category = this.Analysis.categoryAnalysis[selection.cat];
                    if (selection.feature !== null) {
                        var feature = category.featureAnalysis[selection.feature];
                        data = feature.subFeatureAnalysis.map(function (subfeature) {
                            return {
                                Legend: subfeature.subFeatureName,
                                Value: subfeature.weightingPercentage.toFixed(2) * 1
                            }
                        });
                    }
                    else {
                        data = Object.keys(category.featureAnalysis)
                            .map(function (featureReference) {
                                return {
                                    Legend: thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.featureReference == featureReference; })[0].featureName,
                                    Value: category.featureAnalysis[featureReference].featureWeight.toFixed(2) * 1
                                };
                            });
                    }

                }
                else {
                    data = Object.keys(this.Analysis.categoryAnalysis).map(
                        function (category) {
                            return {
                                Legend: category,
                                Value: thisControl.Analysis.categoryAnalysis[category].categoryWeight.toFixed(2) * 1
                            }
                        }
                    );
                }
                data = data.sort(function (a, b) { return b.Value - a.Value; });
                return data;
            },
            SelectedFeatureInAnalysisForSelect: {
                get: function () {
                    var thisControl = this;
                    if (thisControl.SelectFeatureInAnalysis === null)
                        return "[null]";
                    return thisControl.SelectFeatureInAnalysis;
                },
                set: function (setValue) {
                    var thisControl = this;
                    if (setValue === "[null]")
                        thisControl.SelectFeatureInAnalysis = null;
                    else
                        thisControl.SelectFeatureInAnalysis = setValue;
                }
            },
            FeaturesInAnalysisIncludingOverview: function () {
                var thisControl = this;
                var returnedFeatures = thisControl.Analysis === null ? thisControl.DataTableResponse.featureDataTables : thisControl.Analysis.featureAnalysis;
                var result = thisControl.UserSelection.extendedFeatures
                    .filter(function (feature) {
                        return returnedFeatures[feature.featureReference] !== undefined;
                    })
                    .map(function (feature) {
                        return {
                            featureReference: feature.featureReference,
                            featureName: feature.featureName
                        }
                    }).sort(function (a, b) {
                        if (a.featureName < b.featureName)
                            return -1;
                        if (a.featureName > b.featureName)
                            return 1;
                        return 0;
                    });
                return result;
            },
            ComparisonDetails: function () {
                var thisControl = this;

                //thisControl.UserSelection.includedProviders = thisControl.CompareSelection;
                return {
                    selection: thisControl.UserSelection,
                    analysis: thisControl.CurrentAnalysis,
                    firstInsuredName: thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    //firstInsuredName: thisControl.UserSelection.primaryClient.title + " " + thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    //secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.title + " " + thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    providersInDataTables: thisControl.HasDataTables ? thisControl.UserSelection.includedProviders : null,
                    reportTitle: thisControl.ReportTitle,
                    ShouldIncludeDefinitions: true
                };
            },
        },
        mounted: function () {
            var thisControl = this;
            var customerLoaded = function (c) {
                var isClient1 = (
                    c.life1FirstName === thisControl.userSelection.primaryClient.forename
                    && c.life1LastName === thisControl.userSelection.primaryClient.surname
                    && c.life1Gender === helper.GetGender(thisControl.userSelection.primaryClient.classification)
                    && helper.GetDisplayableDate(c.life1DateOfBirth) === helper.GetDisplayableDate(thisControl.userSelection.primaryClient.dateOfBirth)
                )
                if (isClient1) {
                    thisControl.isValidCustomer = true;
                    thisControl.customerType = "PersonOne";
                    if (thisControl.userSelection.secondaryClient !== null) {
                        thisControl.customerType = "Both";
                    }
                }
                var isClient2 = (
                    c.life2FirstName === thisControl.userSelection.primaryClient.forename
                    && c.life2LastName === thisControl.userSelection.primaryClient.surname
                    && c.life2Gender === helper.GetGender(thisControl.userSelection.primaryClient.classification)
                    && helper.GetDisplayableDate(c.life2DateOfBirth) === helper.GetDisplayableDate(thisControl.userSelection.primaryClient.dateOfBirth)
                )
                if (isClient2) {
                    thisControl.isValidCustomer = true;
                    thisControl.customerType = "PersonTwo";
                }
            }
            if (this.UserSelection.PreAnalysis) {
                this.Analysis = this.UserSelection.PreAnalysis;
                delete this.UserSelection.PreAnalysis;
                thisControl.SelectedCustomFilterOptionKeys = [];
                for (var f = 0; f < thisControl.Analysis.providerFilters?.length; f++) {
                        for (var o = 0; o < thisControl.Analysis.providerFilters[f].providerFilterOptions.length; o++) {
                            thisControl.SelectedCustomFilterOptionKeys.push(thisControl.Analysis.providerFilters[f].providerFilterOptions[o].reference);
                        }
                    }

                this.IsLoading = true;
                fetch("/Customer/" + thisControl.UserSelection.customerReference)
                    .then(function (c) { return c.json() })
                    .then(function (c) {
                        customerLoaded(c);
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                    }
                    ).catch(function () {
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                    });
                return;
            }

            var waiting = 3;
            this.LoadingDescription = "Just a moment, we are preparing your comparison...";
            this.IsLoading = true;
            var adToShow = helper.GetAdvert(this.adverts, "WhenPerformingAnalysis", this.UserSelection);
            if (adToShow !== null) {
                this.advert = adToShow;
                waiting++;
                setTimeout(function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                }, adToShow.minimumDurationInSeconds * 1000);
            }
            var done = function () {
                thisControl.IsLoading = false;
                thisControl.advert = null;
            }

            var getAnalysis = function () {
                var analysisUrl = "/Api/"
                var request = thisControl.UserSelection;

                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (response) {
                        thisControl.SelectedSubFeatureInAnalysis = "";
                        thisControl.Analysis = response.analysis;
                        thisControl.analysisResponse = response;
                        thisControl.SelectFeatureInAnalysis = null;
                        thisControl.SelectedCustomFilterOptionKeys = [];
                        for (var f = 0; f < thisControl.Analysis?.providerFilters.length; f++) {
                            for (var o = 0; o < thisControl.Analysis.providerFilters[f].providerFilterOptions.length; o++) {
                                thisControl.SelectedCustomFilterOptionKeys.push(thisControl.Analysis.providerFilters[f].providerFilterOptions[o].reference);
                            }
                        }
                        waiting--;
                        if (waiting === 0) {
                            if (response.creditDeducted && thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '') {
                                thisControl.SubscriptionService.update();
                                ElMessage({
                                    message: "Credit Deducted",
                                    type: 'success'
                                });
                            }
                            done();
                        }
                        if (response.url)
                        {
                            window.location.href = "/";
                            return;
                        }
                        if (thisControl.Analysis.analysisReferecnce) {
                            thisControl.$router.push({ name: 'AnalysisPage', params: { customerReference: thisControl.UserSelection.customerReference, analysisReferecnce: thisControl.Analysis.analysisReferecnce } });
                        }
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        waiting--;
                        if (waiting == 0)
                            done();
                    }
                });
            };

            getAnalysis();

            fetch("/Customer/" + thisControl.userSelection.customerReference)
                .then(function (c) { return c.json() })
                .then(function (c) {
                    customerLoaded(c);
                    waiting--;
                    if (waiting == 0) {
                        done();
                    }
                }).catch(function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                });

            $.ajax({
                type: 'GET',
                url: '/api/Journey/Update/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/50/Life%20Analysis%20Screen",
                success: function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                },
            });
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

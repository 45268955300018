<template>
    <div>
        <advertisement v-if="advert !== null" :advert="advert" :loading-message="LoadingDescription"
                       :user-selection="UserSelection" screen="Express Questions" />
        <loading-control v-if="IsLoading && advert === null" :loading-message="LoadingDescription" />
        <div class="analysisCard"
             v-if="UserSelection.analysisType === 'Comparison' && Analysis !== null && IsLoading === false">
            <div class="left">
                <div class="results">
                    <div class="clientDetails">
                        <div style="display:inline-flex">
                            <div class="labels">
                                Policy details
                            </div>
                            <div class="links" v-if="UserDetails.hasSetNoPricing">
                                <el-link v-on:click.stop="$emit('edit-policy')" class="summaryLabels"
                                         style="text-decoration: underline">Edit</el-link>
                            </div>
                        </div>

                        <div class="detailsItem"
                             v-if="UserSelection.primaryClient !== null && UserSelection.selectedCustomerType != null">
                            <div class="detailsLabel"> Name: </div>
                            <div class="detailsList">
                                {{
 UserSelection.primaryClient.forename + " " +
                                UserSelection.primaryClient.surname
                                }}
                            </div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.secondaryClient !== null">
                            <div class="detailsLabel"></div>
                            <div class="detailsList">
                                {{
 UserSelection.secondaryClient.forename + " " +
                                UserSelection.secondaryClient.surname
                                }}
                            </div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.policyTerm !== null">
                            <div class="detailsLabel"> Term length: </div>
                            <div class="detailsList">{{ UserSelection.policyTerm }}</div>
                        </div>
                        <div class="detailsItem" v-if="UserSelection.toAge !== null">
                            <div class="detailsLabel"> To Age: </div>
                            <div class="detailsList">{{ UserSelection.toAge }}</div>
                        </div>
                        <div class="detailsItem"
                             v-if="!IsTermAssurance && UserSelection.termBenefitAmount == null && UserSelection.termBenefitAmount == ''">
                            <div class="detailsLabel"> Benefit basis: </div>
                            <div class="detailsList">{{ UserSelection.ipBenefitBasis }}</div>
                        </div>
                        <div class="detailsItem"
                             v-if="!IsTermAssurance && UserSelection.ipBenefitBasis === 'Monthly' && UserSelection.termBenefitAmount == null && UserSelection.termBenefitAmount == ''">
                            <div class="detailsLabel"> Benefit Amount: </div>
                            <div class="detailsList">&pound;{{ UserSelection.ipBenefitAmount }}</div>
                        </div>

                        <div class="detailsItem"
                             v-if="IsTermAssurance && UserSelection.termBenefitAmount !== null && UserSelection.termBenefitAmount !== ''">
                            <div class="detailsLabel">
                                {{
 UserSelection.tool.toolReference == 'FIBPro' ||
                                UserSelection.tool.toolReference == 'FIBCIPro' ? 'Annual life cover amount:' : 'Life cover amount: '
                                }}
                            </div>
                            <div class="detailsList">&pound;{{ UserSelection.termBenefitAmount }}</div>
                        </div>

                        <div class="detailsItem"
                             v-if="IsTermAssurance && UserSelection.termCriticalIllnessCoverAmount !== null && UserSelection.termCriticalIllnessCoverAmount !== ''">
                            <div class="detailsLabel">
                                {{
 UserSelection.tool.toolReference == 'FIBPro' ||
                                UserSelection.tool.toolReference == 'FIBCIPro' ? 'Annual critical illness cover amount:' :
                                'Critical illness cover amount:'
                                }}
                            </div>
                            <div class="detailsList">&pound;{{ UserSelection.termCriticalIllnessCoverAmount }}</div>
                        </div>
                        <div class="detailsItem"
                             v-if="IsTermAssurance && UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null && UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== ''">
                            <div class="detailsLabel">
                                {{
 UserSelection.tool.toolReference == 'FIBPro' ||
                                UserSelection.tool.toolReference == 'FIBCIPro' ? 'Annual life or earlier CI cover amount:' :
                                'Life or earlier CI cover amount:'
                                }}
                            </div>
                            <div class="detailsList">
                                &pound;{{ UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount }}
                            </div>
                        </div>
                    </div>

                    <div v-if="UserSelection.analysisType === 'Comparison' && Analysis !== null && IsLoading === false"
                         class="premiumTypeDetails" style="margin-top:16px">
                        <div v-if="Analysis.providerFilters.length > 0" class="labels" style="margin-top:16px;width:100%;">
                            Customise your results
                        </div>
                        <div v-for="(filter, filterIndex) in Analysis.providerFilters" :key="filterIndex">
                            <div class="labels">{{ filter.name }}</div>
                            <div :title="filter.description">
                                <div v-for="option in filter.providerFilterOptions" :key="option.reference">
                                    <el-checkbox v-model="SelectedCustomFilterOptionKeys" :label="option.reference"
                                                 :value="option.reference" style="width: 20px !important; height: 20px !important">
                                        {{ option.option }}
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="navigationbtn">
                        <el-button type="success" v-if="Analysis !== null && IsLoading === false"
                                   @click="IsCancel = IsRequestingReport = true" :disabled="!isValidCustomer">
                            Download
                            Report
                        </el-button>
                        <br />
                        <el-button type="default"
                                   v-if="UserSelection.expressTemplate !== null && IsLoading === false && UserSelection.legacyPolicyProviderReference === ''"
                                   v-on:click.stop="$emit('amend-quality-analysis')">Amend analysis</el-button>
                    </div>

                    <!--<pricing-report-details v-if="IsRequestingReport === true && IsLoading === false"
                                            :user-selection="UserSelection"
                                            :user-details="UserDetails"
                                            :analysis="Analysis"
                                            v-on:generate-report="DownloadPDFReport"
                                            v-on:generate-short-report="DownloadPDFShortReport"
                                            v-on:report-details-cancelled="IsCancel = IsRequestingReport = false"
                                            :current-analysis="Analysis"
                                            :chart-data="OverallChartData"
                                            :chart-data-headings="MainChartAdditionalHeadings"
                                            :compare-selection="CompareSelection" />
                    <apply-now-modal v-if="IsApplyNow === true && IsLoading === false"
                                     v-on:apply-now="ApplyNow"
                                     v-on:cancelled="IsApplyNow = false"
                                     :chart-data="OverallChartData"
                                     :chart-data-headings="MainChartAdditionalHeadings" />
                    <analysis-breakdown v-if="ShowAnalysisBreakdown === true  && IsLoading === false" @closed="ShowAnalysisBreakdown = false" :extended-features="UserSelection.extendedFeatures" :Analysis="Analysis" :provider-selection="SelectedProducts" />-->
                    <report-details v-if="IsRequestingReport === true" :user-selection="UserSelection"
                                    :user-details="UserDetails" :analysis="Analysis" v-on:generate-report="DownloadPDFReport"
                                    v-on:generate-short-report="DownloadPDFSummaryReport"
                                    v-on:report-details-cancelled="IsCancel = IsRequestingReport = false" :current-analysis="Analysis"
                                    :chart-data="MainChartData" :chart-data-headings="MainChartAdditionalHeadings"
                                    :compare-selection="CompareSelection" />
                    <quality-analysis-breakdown v-if="ShowAnalysisBreakdown === true"
                                                @closed="ShowAnalysisBreakdown = false" :extended-features="UserSelection.extendedFeatures"
                                                :analysis="Analysis" :provider-selection="SelectedProducts" />
                </div>
            </div>

            <div class="right">
                <div style="display:inline-flex">
                    <div class="count"> {{ MainChartData.items.length }} results</div>
                </div>
                <br />
                <div>
                    <el-select popper-class="SearchProductsPopper" v-model="CompareSelection"
                               placeholder="Type to search for and/or compare products" class="SearchCustomers" :multiple-limit="3"
                               prefix-icon="el-icon-search" filterable multiple>
                        <el-option-group label="Best in quality">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].BestQuality; })"
                                       :value="cdi.values[0].Reference" :key="'BestQuality' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo"
                                          style="width: 40px !important; height: 40px !important; vertical-align: bottom !important; "
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="Best quality in budget">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].BestInBudget; })"
                                       :value="cdi.values[0].Reference" :key="'BestInBudget' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo"
                                          style="width: 40px !important; height: 40px !important; vertical-align: bottom !important; "
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="Lowest premium">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].LowestPremium; })"
                                       :value="cdi.values[0].Reference" :key="'LowestPremium' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo"
                                          style="width: 40px !important; height: 40px !important; vertical-align: bottom !important; "
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="In budget">
                            <el-option v-for="(cdi) in MainChartData.items.filter(function (a) { return a.values[0].DisplayValues[a.values[0].DisplayValues.length - 1].InBudget; })"
                                       :value="cdi.values[0].Reference" :key="'InBudget' + cdi.values[0].Reference"
                                       style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo"
                                          style="width: 40px !important; height: 40px !important; vertical-align: bottom !important; "
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                        <el-option-group label="All Products">
                            <el-option v-for="(cdi) in MainChartData.items" :value="cdi.values[0].Reference"
                                       :key="cdi.values[0].Reference" style="height:50px;" :label="cdi.values[0].Label">
                                <el-image :src="cdi.values[0].Logo"
                                          style="width: 40px !important; height: 40px !important; vertical-align: bottom !important; "
                                          fit="contain"></el-image>
                                {{ cdi.values[0].Label }}
                            </el-option>
                        </el-option-group>
                    </el-select>
                </div>
                <div v-if="Analysis !== null && IsLoading === false">
                    <div class="orderBy">
                        <div>
                            <label class="allresults">All results (Click on a product to compare in more detail)</label>
                        </div>
                    </div>

                    <div class="AllData">
                        <!--<span>
                            <collapsable-bar-chart max-selection="3" chart-type="Selected" @compare="PerformComparison" :selection="CompareSelection" :title="IsPricing ? 'Pricing': 'Quality Score'" :chart-data="SelectedMainChartData" :expanded-keys="ExpandedProviders" :alternate-value-title="MainChartAdditionalHeadings" :user-selection="UserSelection" :analysis="Analysis" />
                        </span>-->
                        <span>
                            <collapsable-bar-chart :max-selection="3" chart-type="Selected" @compare="PerformComparison"
                                                   :selection="CompareSelection" :title="IsPricing ? 'Pricing' : 'Quality Score'"
                                                   :chart-data="SelectedMainChartData" :expanded-keys="ExpandedProviders"
                                                   :alternate-value-title="MainChartAdditionalHeadings" :user-selection="UserSelection"
                                                   :analysis="Analysis" />
                        </span>
                        <span>
                            <collapsable-bar-chart max-selection="3" chart-type="Unselected" :selection="CompareSelection"
                                                   :title="IsPricing ? 'Pricing' : 'Quality Score'" :chart-data="UnselectedMainChartData"
                                                   :expanded-keys="ExpandedProviders" :alternate-value-title="MainChartAdditionalHeadings"
                                                   :user-selection="UserSelection" :analysis="Analysis" />
                        </span>
                    </div>
                </div>
            </div>
            <!--<div>
                <loading-control v-if="UserSelection.analysisType === 'Comparison'" :loading-message="'Performing Analysis'" />
            </div>-->
            <!--<div class="analisysBody" v-if="UserSelection.analysisType === 'Comparison' && Analysis !== null && false">
                <div class="chartDiv">
                    <span>
                        <collapsable-bar-chart :max-selection="3" :selection="CompareSelection" :chart-title="'Quality Score'" :chart-data="MainChartData" :expanded-keys="ExpandedProviders" :alternate-value-title="MainChartAdditionalHeadings" />
                    </span>
                    <div v-if="CompareSelection.length > 0" class="bottomHugging">


                        <button class="compare" @click="PerformComparison">Analyse Selected Products</button>
                    </div>
                </div>
            </div>-->
        </div>
        <confirm-credits v-if="toRunAfterDeducted !== null" @deduct-credits-cancelled="toRunAfterDeducted = null"
                         @deduct-credit-succeeded="toRunAfterDeducted()" :client="userSelection.primaryClient"
                         :customer-ref="userSelection.customerReference" :product-type="productType" :customer-type="customerType"
                         :legacyPolicyProviderReference="UserSelection.legacyPolicyProviderReference"
                         :toolName="UserSelection.tool.toolName" :legacyProductName="UserSelection.legacyPolicyProvider"
                         :analysisReferecnce="Analysis.analysisReferecnce" />
    </div>
</template>

<style>
    .SearchProductsPopper .el-select-dropdown__wrap {
        max-height: 400px;
    }

    .maxWidth .el-select div {
        width: unset !important;
    }

    .el-select__tags {
        display: flex !important;
        top: 40% !important;
    }

    .el-collapse-item__header {
        display: block !important;
        /*background-color: #F6F6F6 !important;*/
        align-items: center !important;
        height: var(--el-collapse-header-height);
        color: var(--el-collapse-header-text-color) !important;
        cursor: pointer !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .breakdownCollapse .el-collapse-item__header {
        line-height: 0px !important;
        height: 15px !important;
        background: border-box !important;
    }
</style>
<style scoped>
    .el-radio-button__inner:hover {
        color: black;
    }

    /*.el-checkbox__input.is-checked .el-checkbox__inner {
        width: 24px !important;
        height: 24px !important;
    }

    .el-checkbox__inner, .el-checkbox__inner, .el-checkbox__inner::after {
        width: 24px !important;
        height: 24px !important;
    }

    .el-checkbox.is-bordered.el-checkbox--medium .el-checkbox__inner {
        height: 24px !important;
        width: 24px !important;
    }

    .el-checkbox__input {
        width: 24px !important;
        height: 24px !important;
    }

    .el-checkbox__inner {
        width: 24px !important;
        height: 24px !important;
    }*/
    span .el-checkbox__inner {
        width: 24px !important;
        height: 24px !important;
    }

    .el-select-dropdown__item.selected {
        color: black;
        font-weight: 700;
    }

    /*.el-input__inner {
        border-color: black !important;
    }

        .el-input__inner:hover {
            border-color: black;
        }*/


    .analysisCard {
        display: flex;
        max-width: 1144px !important;
        min-width: 1144px !important;
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        border-radius: 8px;
        padding: 24px;
    }

    .orderBy {
        justify-content: space-between;
        display: inline-flex;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 775px;
    }

    .card {
        margin-left: 350px;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .btnQuality {
        justify-content: center;
        align-items: center;
        padding: 5px;
        position: static;
        width: max-content !important;
        background: none;
        height: 40px;
        left: 199px;
        top: 0px;
        border-radius: 8px;
    }

    .radioText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 56px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.04em;
        color: #4B5768;
    }

    .monthlyBenefit {
        position: inherit;
        width: 100px;
        left: 422px;
        margin-left: 15px;
    }

    .price {
        position: inherit;
        width: 80px;
        left: 422px;
        margin-left: 15px;
    }

    .qualityScore {
        position: inherit;
        width: 150px;
        left: 200px;
        margin-left: 35px;
    }

    .links {
        text-decoration: underline;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }

    .title {
        position: relative;
        height: 34px;
        left: 150px;
        top: 150px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 41px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #000000;
    }

    .results {
        width: 317px;
        left: 150px;
        top: 129px;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .clientDetails {
        padding: 16px;
        position: relative;
        width: 285px;
        height: 165px;
        left: 0px;
        top: 0px;
        background-color: #F7F8F9;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .labels {
        position: inherit;
        height: 80px;
        position: static;
        height: 28px;
        left: 0px;
        top: 0.5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #323A46;
    }

    .links {
        position: static;
        width: 109px;
        height: 28px;
        left: 176px;
        top: 0.5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 41px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
        text-decoration-line: underline;
        color: #323A46;
        margin-left: 85px;
    }

    .detailsLabel {
        width: 129px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        align-items: center;
        color: #A0ABBB;
    }

    .detailsList {
        width: 144px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 8px;
        display: inline-block;
        align-items: center;
        color: #323A46;
        margin-left: 12px
    }

    .customizeResult {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 15px;
        position: static;
        width: 285px;
        left: 16px;
        top: 197px;
    }

    .clientBudget {
        position: static;
        width: 280px;
        left: 0px;
        top: 39px;
    }

    .el-input {
        /*  position: absolute;
        width: 280px;
        height: 44px;
        left: 20px;
        background: #FFFFFF;*/
    }

    /*.el-checkbox__inner {
        width: 24px !important;
        height: 24px !important;
        border-radius: 4px;
        display: inline-block;
        position: relative;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        background-color: #fff;
        z-index: 1;
        transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46),background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
    }*/

    .checkboxLabel {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 8px;
        align-items: center;
        color: #323A46;
        padding-left: 10px;
        left: 10px;
    }

    .premiumTypeDetails {
        position: static;
        width: 285px;
        height: 173px;
        left: 16px;
        top: 100px;
        padding-left: 15px;
    }

    .navigationbtn {
        margin-top: 50px;
        padding: 16px;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #323A46;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #1E228C !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: absolute;
        height: 40px;
        left: 297px;
        top: 0px;
    }

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 285px !important;
        height: 50px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success:focus,
    .el-button--success:hover {
        background: #1E228C !important;
        border-radius: 8px !important;
        /*border-color: #1E228C !important;*/
    }

    .right {
        padding: 16px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-left: 20px;
    }

    .count {
        position: static;
        width: 330px;
        height: 40px;
        left: 0px;
        top: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 41px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .allresults {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        padding-top: 16px;
        margin-bottom: 10px;
    }

    .SearchCustomers {
        margin-top: 10px !important;
        border-radius: 8px !important;
        width: 790px !important;
        min-height: 48px !important;
        display: inline-block !important;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
        position: static;
        width: 785px;
        height: 98px;
        left: 16px;
        top: 162px;
        background: #F6F6F6;
        border-radius: 8px;
    }

    .logo {
        position: absolute;
        width: 56px;
        height: 61.25px;
        background: url('https://logo.clearbit.com/spotify.com');
        position: absolute;
    }

    .text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #48494D;
    }

    .analysisbottomMargin50 {
        width: 100% !important;
    }

    .bottomHugging {
        position: sticky;
        bottom: 60px;
        height: 50px;
        background-color: rgba(30, 34, 140, 0.5);
        padding-top: 10px;
    }

    .compare {
        width: 300px;
    }

    .chartDiv {
        padding-left: 330px;
        min-width: auto;
        display: block
    }

        .chartDiv div,
        .chartDiv div div {
            display: block;
            min-width: auto;
        }

    #Quality + label {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    #Pricing + label {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    fieldset {
        text-align: center;
        margin-left: auto;
        width: 425px;
        margin-right: auto;
    }

        fieldset.customise {
            text-align: left;
            border: 3px solid #1e228c;
            padding: 0 0 0 10px;
            width: 300px;
            float: left;
            position: sticky;
            top: 0;
        }

            fieldset.customise legend {
                padding: 5px 5px 5px 5px;
                float: left;
            }

            fieldset.customise h3 {
                padding: 5px 5px 5px 5px;
                clear: both;
                color: #1e228c;
                font-weight: normal;
                border-top: 1px solid #1e228c;
            }

    .budget {
        display: block;
        margin: 5px 5px 5px 5px;
        position: relative;
        padding-left: 25px;
        border: solid 1px black;
    }

        .budget::before {
            content: "£";
            width: 10px;
            font-family: 'Roboto';
            color: #1e228c;
            display: inline-block;
            padding-left: 5px;
            font-size: 24px;
            text-align: left;
            position: absolute;
            left: 0px;
        }

        .budget input {
            border: none;
            font-size: 24px;
            padding: 0px 0px 0px 0px;
            display: block;
            width: 100%;
        }
</style>

<script>
    //import BarChart from './BarChart.vue';
    import QualityAnalysisBreakdown from './QualityAnalysisBreakdown.vue';
    import CollapsableBarChart from './CollapsableBarChart.vue';
    import ReportDetails from './ReportDetails.vue';
    import LoadingControl from './LoadingControl.vue';
    import Advertisement from './Advertisement.vue';
    import $ from 'jquery';
    import helper from './HelperMethods.vue';
    import ConfirmCredits from './ConfirmCredits.vue';
    import { ElMessage } from 'element-plus';
    //import ApplyNowModal from './ApplyNowModal.vue';

    export default {
        name: 'AnalysisControl',
        components: { QualityAnalysisBreakdown, CollapsableBarChart, ReportDetails, LoadingControl, Advertisement, ConfirmCredits },
        inject: ["SubscriptionService"],
        props: ["userSelection", "userDetails", "adverts"],
        data: function () {
            return {
                deferredPeriod: ['1 day', '1 Week', '2 Weeks', '1 Month', '2 Month', '3 Month', '6 Month', '12 Month', '24 Months'],
                IsComparing: false,
                LoadingDescription: null,
                ShowAnalysisBreakdown: false,
                advert: null,
                CompareSelection: [],
                Analysis: null,
                AnalysisResponse: null,
                Error: null,
                SelectionAnalysisOption: JSON.stringify({ title: "Analysis", cat: null, feature: null }),
                IsRequestingReport: false,
                IsRequestingSummaryReport: false,
                IsLoading: false,
                DataTableResponse: null,
                SelectFeatureInAnalysis: null,
                Filter: 'All',
                ProductStarFilter: 'All',
                AnalysisPremiumTypeFilter: ['Guaranteed', 'Guaranteed_AgeCosted', 'Reviewable', 'Reviewable_AgeCosted'],
                SelectedSubFeatureInAnalysis: null,
                Features: null,
                IsPricing: '',
                ExtendedFeatures: null,
                Providers: null,
                IsCancel: true,
                ExpandedProviders: { "keys": [] },
                SelectedCustomFilterOptionKeys: [],
                customerType: null,
                isValidCustomer: false,
                toRunAfterDeducted: null,
                IsDeductingCredit: false,
            }
        },
        methods: {
            DownloadPDFReport: function (request) {
                var thisControl = this;
                thisControl.toRunAfterDeducted = null;

                thisControl.SubscriptionService.hasCredit(thisControl.userSelection.customerReference, thisControl.productType, thisControl.customerType).then(function (hasCredit) {
                    thisControl.UserSelection.includedProviders = thisControl.CompareSelection;
                    var waiting = 1;
                    var pdfToDownload = null;
                    var reportreference = null;
                    var done = function () {
                        helper.downloadFile("application/pdf", helper.GetClientsName(thisControl.UserSelection) + ".pdf", pdfToDownload, '/customerreport/' + thisControl.UserSelection.customerReference + '/' + thisControl.Analysis.analysisReferecnce + "/" + reportreference);
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                        thisControl.IsRequestingReport = false;
                    };
                    var analysisUrl = "/report/pdf";
                    var adToShow = helper.GetAdvert(thisControl.adverts, "WhenDownloadingReport", thisControl.UserSelection);
                    if (adToShow !== null) {
                        thisControl.advert = adToShow;
                        waiting++;
                        setTimeout(function () {
                            waiting--;
                            if (waiting == 0)
                                done();
                        }, adToShow.minimumDurationInSeconds * 1000);
                    }
                    thisControl.LoadingDescription = "Just a moment, we are preparing your report...";
                    //Legacy - No need to deduct the credit once report downloaded
                    if (hasCredit || (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '')) {
                        thisControl.IsLoading = true;
                        $.ajax({
                            type: 'POST',
                            url: analysisUrl,
                            contentType: 'application/json',
                            data: JSON.stringify(request),
                            success: function (pdf, _, request) {
                                pdfToDownload = pdf;
                                reportreference = request.getResponseHeader("ReportReference");
                                waiting--;
                                if (waiting === 0)
                                    done();
                            },
                            error: function (xhr) {
                                if (xhr.status == 401) {
                                    window.location = "./Api/signin";
                                    return;
                                }
                                waiting--;
                                if (waiting === 0)
                                    done();
                            }
                        });
                    }
                    else {
                        thisControl.toRunAfterDeducted = function () {
                            thisControl.IsLoading = true;
                            thisControl.toRunAfterDeducted = null;
                            $.ajax({
                                type: 'POST',
                                url: analysisUrl,
                                contentType: 'application/json',
                                data: JSON.stringify(request),
                                success: function (pdf, _, request) {
                                    pdfToDownload = pdf;
                                    reportreference = request.getResponseHeader("ReportReference");
                                    waiting--;
                                    if (waiting === 0) {
                                        done();
                                        if (pdfToDownload != undefined || pdfToDownload != null || pdfToDownload != "") {
                                            thisControl.SubscriptionService.update();
                                            ElMessage({
                                                message: "Credit Deducted",
                                                type: 'success'
                                            });
                                        }
                                    }
                                },
                                error: function (xhr) {
                                    if (xhr.status == 401) {
                                        window.location = "./Api/signin";
                                        return;
                                    }
                                    waiting--;
                                    if (waiting === 0)
                                        done();
                                }
                            });
                        }
                    }
                });
            },
            DownloadPDFSummaryReport: function (request) {
                var thisControl = this;
                thisControl.toRunAfterDeducted = null;

                thisControl.SubscriptionService.hasCredit(thisControl.userSelection.customerReference, thisControl.productType, thisControl.customerType).then(function (hasCredit) {
                    //var request = thisControl.ReportDetails();
                    var waiting = 1;
                    var pdfToDownload = null;
                    var reportreference = null;
                    thisControl.LoadingDescription = "Just a moment, we are preparing your report...";
                    var done = function () {
                        helper.downloadFile("application/pdf", helper.GetClientsName(thisControl.UserSelection) + ".pdf", pdfToDownload, '/customerreport/' + thisControl.UserSelection.customerReference + '/' + thisControl.Analysis.analysisReferecnce + "/" + reportreference);
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                        thisControl.IsRequestingReport = false;
                    };

                    var analysisUrl = "/report/SummaryPdf";
                    var adToShow = helper.GetAdvert(thisControl.adverts, "WhenDownloadingReport", thisControl.UserSelection);
                    if (adToShow !== null) {
                        thisControl.advert = adToShow;
                        waiting++;
                        setTimeout(function () {
                            waiting--;
                            if (waiting == 0)
                                done();
                        }, adToShow.minimumDurationInSeconds * 1000);
                    }
                    if (hasCredit || (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '')) {
                        thisControl.IsLoading = true;
                        $.ajax({
                            type: 'POST',
                            url: analysisUrl,
                            contentType: 'application/json',
                            data: JSON.stringify(request),
                            success: function (pdf, _, request) {
                                pdfToDownload = pdf;
                                reportreference = request.getResponseHeader("ReportReference");
                                waiting--;
                                if (waiting === 0)
                                    done();
                            },
                            error: function (xhr) {
                                if (xhr.status == 401) {
                                    window.location = "./Api/signin";
                                    return;
                                }
                                waiting--;
                                if (waiting === 0)
                                    done();
                                //alert("Problem Getting Report" + xhr.responseText);
                            }
                        });
                    }
                    else {
                        thisControl.toRunAfterDeducted = function () {
                            //thisControl.DownloadPDFSummaryReport()//try download again...
                            thisControl.IsLoading = true;
                            thisControl.toRunAfterDeducted = null;
                            $.ajax({
                                type: 'POST',
                                url: analysisUrl,
                                contentType: 'application/json',
                                data: JSON.stringify(request),
                                success: function (pdf, _, request) {
                                    pdfToDownload = pdf;
                                    reportreference = request.getResponseHeader("ReportReference");
                                    waiting--;
                                    if (waiting === 0) {
                                        done();
                                        if (pdfToDownload != undefined || pdfToDownload != null || pdfToDownload != "") {
                                            thisControl.SubscriptionService.update();
                                            ElMessage({
                                                message: "Credit Deducted",
                                                type: 'success'
                                            });
                                        }
                                    }
                                },
                                error: function (xhr) {
                                    if (xhr.status == 401) {
                                        window.location = "./Api/signin";
                                        return;
                                    }
                                    waiting--;
                                    if (waiting === 0)
                                        done();
                                }
                            });
                        }
                    }
                });
            },
            ReportDetails: function () {
                var thisControl = this;
                thisControl.UserSelection.includedProviders = thisControl.CompareSelection;

                thisControl.UserSelection.reportTitle = thisControl.ReportTitle;
                thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 }).forEach(function (feature) {
                    if (thisControl.UserSelection.includeFeatureInDataTable !== undefined
                        && thisControl.UserSelection.includeFeatureInDataTable.length > 0
                        && thisControl.UserSelection.includeFeatureInDataTable.includes(feature.featureReference))
                        feature.includeInDataTable = true;
                });

                thisControl.UserSelection.categoryWeightings.filter(function (category) { return category.weight > 0 }).forEach(function (category) {
                    if (thisControl.UserSelection.includeFeatureInDataTable !== undefined && thisControl.UserSelection.includeCategoryInDataTable.length > 0 && thisControl.UserSelection.includeCategoryInDataTable.includes(category.category)) {
                        thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 && feature.featureCategory === category.category }).forEach(function (feature) {
                            feature.includeInDataTable = true;
                        });
                    }
                });

                return {
                    selection: thisControl.UserSelection,
                    analysis: thisControl.Analysis,
                    //firstInsuredName: thisControl.UserSelection.primaryPersonName,
                    //secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryPersonName,
                    firstInsuredName: thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    providersInDataTables: thisControl.HasDataTables ? thisControl.UserSelection.includedProviders : null,
                    reportTitle: "Test",
                    ShouldIncludeDefinitions: thisControl.ShouldIncludeDefinitions
                };
            },
            PerformComparison: function () {
                var comparisonUrl = "/api/Journey/Comparison";
                var thisControl = this;
                var request = thisControl.ReportDetails();
                thisControl.IsComparing = true;
                $.ajax({
                    type: 'POST',
                    url: comparisonUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function () {
                        thisControl.IsComparing = false;
                        thisControl.ShowAnalysisBreakdown = true;
                    },
                    error: function (xhr) {
                        thisControl.IsComparing = false;
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //alert("Problem Getting Report" + xhr.responseText);
                    }
                });
            },
            AddLegacyProductToCompareSelection: function () {
                if (!this.CompareSelection.includes(this.UserSelection.legacyPolicyProviderReference))
                    this.CompareSelection.push(this.UserSelection.legacyPolicyProviderReference);
            }
        },
        created: function () {

        },
        watch: {
            FilterProductsInBudget: {
                deep: true,
                handler: function (newValue) {
                    var budget = this.UserSelection.clientBudget === "" ? null : this.UserSelection.clientBudget;
                    var thisControl = this;
                    if (newValue !== null) {
                        this.UserSelection.filteredQuotes = newValue.map(function (p) {
                            return {

                                errors: p.errors,
                                price: p.price,
                                productID: p.productID,
                                provider: p.provider,
                                displayName: p.displayName,
                                monthlyBenefit: p.monthlyBenefit,
                                reference: p.quoteID,
                                benefitType: p.benefitType,
                                premiumType: p.premiumType
                            };
                        });
                        var premiumReasonMap =
                        {
                            'Guaranteed': 'Guaranteed premium',
                            'Guaranteed_AgeCosted': 'Guaranteed - age costed premium',
                            'Reviewable': 'Reviewable premium',
                            'Reviewable_AgeCosted': 'Reviewable - age costed'
                        }

                        var benefitReasonMap = {
                            '_1_Year_Benefit_Period': '1 Year Benefit Period',
                            '_2_Year_Benefit_Period': '2 Year Benefit Period',
                            '_5_Year_Benefit_Period': '5 Year Benefit Period',
                            'Full_Term': 'Full term benefit period'
                        }

                        this.UserSelection.unavailableProducts =
                            helper.mergeArrays([
                                //Unavailable products from iPipeline.
                                this.UserSelection.highLevelUnavailableProducts,
                                //Products With Errors.
                                this.UserSelection.availableIPProducts.filter(function (p) { return p.errors.length > 0; }).map(function (av) {
                                    return {
                                        displayName: av.displayName,
                                        productID: av.productID,
                                        reasons: av.errors,
                                        provider: av.provider
                                    };
                                }),
                                //Products Filtered Out
                                this.AllProducts.map(function (p) {
                                    {
                                        return {
                                            reasons: [
                                                thisControl.AnalysisPremiumTypeFilter.includes(p.premiumType) ? null : premiumReasonMap[p.premiumType],
                                                thisControl.AnalysisBenefitTypeFilter.includes(p.benefitType) ? null : benefitReasonMap[p.benefitType],
                                                thisControl.RemoveNotInBudget && budget !== null && budget < p.price ? "Not in budget" : null
                                            ].filter(function (a) { return a !== null; }),
                                            price: p.price,
                                            productID: p.productID,
                                            provider: p.provider,
                                            displayName: p.displayName,
                                            isFiltered: true

                                        }
                                    }
                                }).filter(function (a) { return a.reasons.length > 0; })
                            ]);

                        if (this.UserDetails.isAdminUser) {
                            console.log(this.UserSelection.filteredQuotes.map(function (a) { return a.productID + ": " + a.displayName }).join("\n"));
                        }
                    }
                }
            },
            FilteredProducts: {
                deep: true,
                handler: function (newValue) {
                    if (newValue !== null) {
                        this.UserSelection.filteredProducts = newValue.map(function (p) {
                            return p;
                        });
                        this.UserSelection.unavailableProducts = this.UnavailableProducts;
                    }
                }
            },
            DisplayableProductFilters: {
                deep: true,
                handler: function (newvalue) {
                    this.UserSelection.productFilters = newvalue;
                }
            },
            MainChartData: {
                deep: true,
                handler: function (val) {
                    if (val == null)
                        return;
                    var newSelection = this.CompareSelection.filter(function (s) {
                        return val.items.some(function (g) { return g.values.some(function (v) { return v.Reference === s; }); })
                    });
                    if (newSelection.length < this.CompareSelection.length)
                        this.CompareSelection = newSelection;

                    newSelection = this.UserSelection.includedProviders.filter(function (s) {
                        return val.items.some(function (g) { return g.values.some(function (v) { return v.Reference === s; }); })
                    });
                    if (newSelection.length < this.UserSelection.includedProviders.length)
                        this.UserSelection.includedProviders = newSelection;

                    if (this.UserSelection.legacyPolicyProviderReference) {
                        this.AddLegacyProductToCompareSelection();
                    }

                    this.UserSelection.productsInFocus = this.CompareSelection;
                }
            },
            SelectFeatureInAnalysis: function (val, oldVal) {
                var thisControl = this;
                if (val === oldVal)
                    return;
                if (thisControl.Analysis === null)
                    return;
                if (val === null) {
                    thisControl.SelectedSubFeatureInAnalysis = null;
                    return
                }
                var featureAnalysis = thisControl.Analysis.featureAnalysis[val];
                if (featureAnalysis !== null && featureAnalysis.subFeatureAnalysis !== null && featureAnalysis.subFeatureAnalysis.length > 0)
                    thisControl.SelectedSubFeatureInAnalysis = featureAnalysis.subFeatureAnalysis[0].subFeatureReference; //select the first subfeature...
            },
            ProductStarFilter: function () {
                if (this.MainChartData !== null) {
                    if (this.ProductStarFilter === 'Best quality in budget') {
                        this.MainChartData = null;

                        //this.MainChartData.items.filter(function (itm) { if (itm.values) return values }).filter(function (val) { return val[0] }).values[0].DisplayValues[3].BestInBudget
                        //this.MainChartData.items[0].values[0].DisplayValues[3].BestInBudget
                    }
                    //else if (this.ProductStarFilter === 'In budget') {

                    //}
                    //else if (this.ProductStarFilter === 'Lowest premium') {

                    //}
                    //else { }
                }
            },
            "UserSelection.clientBudget": function (val) {
                if (isFinite(val))
                    if (val * 1 > 0) {
                        //there is a budget so all no action required
                        return;
                    }
                //there is no budget so check filters
                if (this.Filter === 'In budget' || this.Filter === 'Best quality in budget')
                    this.Filter = 'All';
            },
            "IsRequestingReport": function (val) {
                if (this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '' && val === true && this.CompareSelection.length > 2) {
                    this.CompareSelection = [];
                    this.AddLegacyProductToCompareSelection();
                }
            }
        },
        computed: {
            productType: {
                get: function () {
                    switch (this.UserSelection.tool.toolReference) {
                        case "IPPro":
                            return "Income Protection";
                        case "LifePro":
                        case "MPPro":
                        case "CIPlus":
                        case "MPCIPro":
                        case "FIBPro":
                        case "FIBCIPro":
                        case "KeyPersonPro":
                        case "KeyPersonCIPro":
                            return "Term Assurance";
                        default:
                            throw Error("Unknown tool");
                    }
                }
            },
            UserSelection: {
                get: function () { return this.AnalysisResponse?.userSelection || this.userSelection; }
            },
            UserDetails: {
                get: function () { return this.userDetails; }
            },
            UnavailableProducts: function () {
                var thisControl = this;
                if (this.Analysis === null)
                    return null;
                return thisControl.Analysis.providers.map(
                    function (provider) {
                        return {
                            reasons: helper.mergeArrays(thisControl.Analysis.providerFilters.map(function (filter) {
                                return filter.providerFilterOptions.filter(function (fo) {
                                    if (provider.providerFilters !== undefined && provider.providerFilters !== null)
                                        return provider.providerFilters[filter.providerFilterReference].includes(fo.reference) && !thisControl.SelectedCustomFilterOptionKeys.includes(fo.reference);
                                    else
                                        return null;

                                }).map(function (fo) { return fo.option; });
                            }
                            )),
                            productID: provider.providerReference,
                            displayName: provider.providerName,
                            reference: provider.providerReference,
                            IsFiltered: true
                        }
                    }).filter(function (up) { return up.reasons.length > 0; })
            },
            MainChartAdditionalHeadings: function () {

                return [['Quality', 'Score']];
            },
            FilteredProducts: function () {
                var thisControl = this;

                if (thisControl.Analysis === null)
                    return null;

                var products = thisControl.Analysis.providers;

                products = helper.ApplyProductFilters(
                    products.filter(function (p) { return p.providerReference !== thisControl.UserSelection.legacyPolicyProviderReference }),
                    function (p) { return p; },
                    thisControl.Analysis.providerFilters,
                    thisControl.SelectedCustomFilterOptionKeys,
                );

                //Added the legacy products from all prodcuts
                if (thisControl.userSelection.legacyPolicyProviderReference !== null && thisControl.userSelection.legacyPolicyProviderReference !== '')
                    products.push(thisControl.Analysis.providers.filter(function (p) { return p.providerReference === thisControl.userSelection.legacyPolicyProviderReference; })[0]);

                /*
                products = products.filter(
                    function (provider) {

                        return !thisControl.Analysis.providerFilters.some(function (filter) {
                            return !filter.providerFilterOptions.some(function (fo) {
                                return provider.providerFilters[filter.providerFilterReference].includes(fo.reference) && thisControl.SelectedCustomFilterOptionKeys.includes(fo.reference);
                            })
                        });
                    }
                );*/

                return products;
            },
            MainChartData: function () {
                var base = this.Analysis;
                if (base === null)
                    return null;
                var thisControl = this;

                var products = thisControl.FilteredProducts;

                var data = products.map(function (p) {
                    return {
                        Label: p.providerReference === thisControl.userSelection.legacyPolicyProviderReference ? thisControl.userSelection.legacyPolicyProvider : p.providerName,
                        Logo: '/logos/' + (p.providerReference === thisControl.userSelection.legacyPolicyProviderReference ? "legacy" : helper.GetProviderLogoByName(p.providerName)) + '.png',
                        Tooltip: null,
                        Value: parseFloat(base.providerScores[p.providerReference]).toFixed(2) * 1,
                        Color: p.providerColor,
                        Reference: p.providerReference,
                        Group: p.providerReference,
                        DisplayValue: "", //parseFloat(base.providerScores[p.productID]).toFixed(0),
                        HasTooltip: false,
                        AlternateDisplayValue: "",
                        DisplayValues:
                            [
                                parseFloat(base.providerScores[p.providerReference]).toFixed(1),
                            ],
                        outlineBoxColor: null,
                        datatableBoxColor: null,
                        tableData: thisControl.UserSelection.categoryWeightings.filter(function (cat) {
                            return cat.weight > 0
                        }).map(function (cat) {
                            return { category: cat.category, score: thisControl.Analysis.categoryAnalysis[cat.category].providerScores[p.providerReference].toFixed(0) * 1 };
                        }),
                        IsLegacy: p.providerReference === thisControl.userSelection.legacyPolicyProviderReference
                    }
                });

                data = data.sort(function (a, b) { return b.Value - a.Value; });

                return {
                    showLegend: false,
                    max: 100.0,
                    items: helper.groupByArray(data, "Reference")
                };
            },
            SelectedMainChartData: function () {
                var result = this.MainChartData;
                var thisControl = this;
                if (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '')
                    thisControl.AddLegacyProductToCompareSelection();

                return {
                    showLegend: result.showLegend,
                    max: result.max,
                    items: result.items
                        .filter(function (a) { return thisControl.CompareSelection.indexOf(a.values[0].Reference) >= 0 })
                        .sort(function (a) { return a.values[0]?.IsLegacy ? -1 : 1 })
                };
            },
            UnselectedMainChartData: function () {
                var result = this.MainChartData;
                var thisControl = this;

                return {
                    showLegend: result.showLegend,
                    max: result.max,
                    items: result.items.filter(function (a) { return thisControl.CompareSelection.indexOf(a.values[0].Reference) < 0 })
                };
            },
            SelectedProducts: function () {
                var thisControl = this;
                return this.CompareSelection.map(function (id) {
                    return thisControl.FilteredProducts.filter(function (p) { return p.providerReference === id })[0];
                })

            },
            DisplayableProductFilters: function () {
                if (this.Analysis === null)
                    return null;
                var thisControl = this;
                var result =
                    helper.mergeArrays(
                        this.Analysis.providerFilters
                            .map(function (o) {
                                return o.providerFilterOptions.filter(function (f) { return thisControl.SelectedCustomFilterOptionKeys.indexOf(f.reference) == -1 }).map(function (f) { return f.option; });
                            }));

                return result;
            },
            ProductData: function () {
                var base = this.Analysis;
                if (base === null)
                    return null;
                var thisControl = this;
                var products = thisControl.FilteredProducts;
                var budget = thisControl.UserSelection.clientBudget === "" ? null : thisControl.UserSelection.clientBudget * 1;
                var prices = products.map(function (p) { return p.price; });
                var scores = this.FilteredProducts.map(function (p) { return parseFloat(base.providerScores[p.productID]); });
                var scoresInBudget = this.FilteredProducts.filter(function (p) { return budget !== null && budget >= (1 * p.price); }).map(function (p) { return parseFloat(base.providerScores[p.productID]); });
                var minPrice = Math.min(...prices);
                var bestQuality = Math.max(...scores);
                var bestQualityInBudget = Math.max(...scoresInBudget);
                if (this.IsPricing) {

                    return {
                        showLegend: false,
                        max: Math.max(...thisControl.AllProducts.map(function (p) { return p.price; })),
                        items: helper.groupByArray(thisControl.FilterProductsInBudget
                            .map(
                                function (p) {
                                    return {
                                        Logo: '/logos/' + p.provider + '.png',
                                        Color: thisControl.ProviderLookup[p.productID].providerColor,
                                        Value: p.price,
                                        Label: p.displayName,
                                        Tooltip: ((p.warnings.length > 0 ? "Warnings:\n" + p.warnings.join("\n") : "\n") + (p.errors.length > 0 ? "Errors:\n" + p.errors.join("\n") : "\n")).trim(),
                                        Reference: p.quoteID,
                                        Group: p.provider,
                                        DisplayValue: "",
                                        HasTooltip: p.warnings.length > 0,
                                        DisplayValues:
                                            (thisControl.UserSelection.ipBenefitBasis === "Maximum") ?
                                                [
                                                    parseFloat(base.providerScores[p.productID]).toFixed(0),
                                                    "\u00A3" + (1 * p.price).toFixed(2),
                                                    "\u00A3" + (1 * p.monthlyBenefit).toFixed(2),
                                                    {
                                                        BestQuality: bestQuality === parseFloat(base.providerScores[p.productID]),
                                                        BestInBudget: budget !== null && budget >= (1 * p.price) && bestQualityInBudget === parseFloat(base.providerScores[p.productID]),
                                                        InBudget: budget !== null && budget >= (1 * p.price),
                                                        LowestPremium: minPrice === 1 * p.price
                                                    }
                                                ]
                                                :
                                                [
                                                    parseFloat(base.providerScores[p.productID]).toFixed(0),
                                                    "\u00A3" + (1 * p.price).toFixed(2),
                                                    {
                                                        BestQuality: bestQuality === parseFloat(base.providerScores[p.productID]),
                                                        BestInBudget: budget !== null && budget >= (1 * p.price) && bestQualityInBudget === parseFloat(base.providerScores[p.productID]),
                                                        InBudget: budget !== null && budget >= (1 * p.price),
                                                        LowestPremium: minPrice === 1 * p.price
                                                    }
                                                ],
                                        outlineBoxColor: null,
                                        datatableBoxColor: base.providerScores[p.productID] > 50 ? 'yellow' : null
                                    }
                                }).sort(function (a, b) { return a.Value - b.Value; }), ("Reference"))
                    }
                }
                else {

                    var data = thisControl.FilterProductsInBudget.map(function (p) {
                        return {
                            Logo: '/logos/' + p.provider + '.png',
                            Label: p.displayName,
                            Tooltip: ((p.warnings.length > 0 ? "Warnings:\n" + p.warnings.join("\n") : "\n") + (p.errors.length > 0 ? "Errors:\n" + p.errors.join("\n") : "\n")).trim(),
                            Value: parseFloat(base.providerScores[p.productID]).toFixed(2) * 1,
                            Color: thisControl.ProviderLookup[p.productID].providerColor,
                            Reference: p.quoteID,
                            Group: p.provider,
                            DisplayValue: '',
                            HasTooltip: p.warnings.length > 0,
                            DisplayValues:
                                (thisControl.UserSelection.ipBenefitBasis === "Maximum") ?
                                    [
                                        parseFloat(base.providerScores[p.productID]).toFixed(0),
                                        "\u00A3" + (1 * p.price).toFixed(2),
                                        "\u00A3" + (1 * p.monthlyBenefit).toFixed(2),
                                        {
                                            BestQuality: bestQuality === parseFloat(base.providerScores[p.productID]),
                                            BestInBudget: budget !== null && budget >= (1 * p.price) && bestQualityInBudget === parseFloat(base.providerScores[p.productID]),
                                            InBudget: budget !== null && budget >= (1 * p.price),
                                            LowestPremium: minPrice === 1 * p.price
                                        }
                                    ]
                                    :
                                    [
                                        parseFloat(base.providerScores[p.productID]).toFixed(0),
                                        "\u00A3" + (1 * p.price).toFixed(2),
                                        {
                                            BestQuality: bestQuality === parseFloat(base.providerScores[p.productID]),
                                            BestInBudget: budget !== null && budget >= (1 * p.price) && bestQualityInBudget === parseFloat(base.providerScores[p.productID]),
                                            InBudget: budget !== null && budget >= (1 * p.price),
                                            LowestPremium: minPrice === 1 * p.price
                                        }
                                    ],
                            outlineBoxColor: null,
                            datatableBoxColor: null
                        }
                    });
                }
                data = data.sort(function (a, b) { return b.Value - a.Value; });

                return data;
            },
            OverallChartData: function () {

                var base = this.Analysis;
                if (base === null)
                    return null;
                var thisControl = this;
                var products = thisControl.FilteredProducts;
                var budget = thisControl.UserSelection.clientBudget === "" ? null : thisControl.UserSelection.clientBudget * 1;
                var prices = products.map(function (p) { return p.price; });
                var scores = this.FilteredProducts.map(function (p) { return parseFloat(base.providerScores[p.productID]); });
                var scoresInBudget = this.FilteredProducts.filter(function (p) { return budget !== null && budget >= (1 * p.price); }).map(function (p) { return parseFloat(base.providerScores[p.productID]); });
                var minPrice = Math.min(...prices);
                var bestQuality = Math.max(...scores);
                var bestQualityInBudget = Math.max(...scoresInBudget);
                var data = thisControl.FilterProductsInBudget.map(function (p) {
                    return {
                        Label: p.displayName,
                        Tooltip: ((p.warnings.length > 0 ? "Warnings:\n" + p.warnings.join("\n") : "\n") + (p.errors.length > 0 ? "Errors:\n" + p.errors.join("\n") : "\n")).trim(),
                        Value: parseFloat(base.providerScores[p.productID]).toFixed(2) * 1,
                        Color: thisControl.ProviderLookup[p.productID].providerColor,
                        Reference: p.quoteID,
                        Logo: '/logos/' + p.provider + '.png',
                        Group: p.provider,
                        DisplayValue: '',
                        HasTooltip: p.warnings.length > 0,
                        tableData: [],
                        DisplayValues:
                            (thisControl.UserSelection.ipBenefitBasis === "Maximum") ?
                                [
                                    parseFloat(base.providerScores[p.productID]).toFixed(0),
                                    "\u00A3" + (1 * p.price).toFixed(2),
                                    "\u00A3" + (1 * p.monthlyBenefit).toFixed(2),
                                    {
                                        BestQuality: bestQuality === parseFloat(base.providerScores[p.productID]),
                                        BestInBudget: budget !== null && budget >= (1 * p.price) && bestQualityInBudget === parseFloat(base.providerScores[p.productID]),
                                        InBudget: budget !== null && budget >= (1 * p.price),
                                        LowestPremium: minPrice === 1 * p.price
                                    }
                                ]
                                :
                                [
                                    parseFloat(base.providerScores[p.productID]).toFixed(0),
                                    "\u00A3" + (1 * p.price).toFixed(2),
                                    {
                                        BestQuality: bestQuality === parseFloat(base.providerScores[p.productID]),
                                        BestInBudget: budget !== null && budget >= (1 * p.price) && bestQualityInBudget === parseFloat(base.providerScores[p.productID]),
                                        InBudget: budget !== null && budget >= (1 * p.price),
                                        LowestPremium: minPrice === 1 * p.price
                                    }
                                ],
                        outlineBoxColor: null,
                        datatableBoxColor: null
                    }
                });

                data = data.sort(function (a, b) { return b.Value - a.Value; });

                return {
                    showLegend: false,
                    max: 100.0,
                    items: helper.groupByArray(data, "Reference")
                };
            },
            WeightChartTitle: function () {
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection.cat !== null) {
                    return "Feature Breakdown";
                }
                return "Category Breakdown";
            },
            HasWeightChart: function () {
                if (this.IsPricing) return false;
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection.cat !== null) {
                    var category = this.Analysis.categoryAnalysis[selection.cat];
                    if (selection.feature !== null) {
                        var feature = category.featureAnalysis[selection.feature];
                        return (typeof feature.subFeatureAnalysis) !== "undefined" && feature.subFeatureAnalysis !== null && feature.subFeatureAnalysis.length > 0;
                    }
                    return true; //category analysis always has a weight graph
                }
                return true; //overall always has a weight graph
            },
            WeightChartData: function () {
                var data;
                var thisControl = this;
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection.cat !== null) {
                    var category = this.Analysis.categoryAnalysis[selection.cat];
                    if (selection.feature !== null) {
                        var feature = category.featureAnalysis[selection.feature];
                        data = feature.subFeatureAnalysis.map(function (subfeature) {
                            return {
                                Legend: subfeature.subFeatureName,
                                Value: subfeature.weightingPercentage.toFixed(2) * 1
                            }
                        });
                    }
                    else {
                        data = Object.keys(category.featureAnalysis)
                            .map(function (featureReference) {
                                return {
                                    Legend: thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.featureReference == featureReference; })[0].featureName,
                                    Value: category.featureAnalysis[featureReference].featureWeight.toFixed(2) * 1
                                };
                            });
                    }

                }
                else {
                    data = Object.keys(this.Analysis.categoryAnalysis).map(
                        function (category) {
                            return {
                                Legend: category,
                                Value: thisControl.Analysis.categoryAnalysis[category].categoryWeight.toFixed(2) * 1
                            }
                        }
                    );
                }
                data = data.sort(function (a, b) { return b.Value - a.Value; });
                return data;
            },
            SelectedFeatureInAnalysisForSelect: {
                get: function () {
                    var thisControl = this;
                    if (thisControl.SelectFeatureInAnalysis === null)
                        return "[null]";
                    return thisControl.SelectFeatureInAnalysis;
                },
                set: function (setValue) {
                    var thisControl = this;
                    if (setValue === "[null]")
                        thisControl.SelectFeatureInAnalysis = null;
                    else
                        thisControl.SelectFeatureInAnalysis = setValue;
                }
            },
            FeaturesInAnalysisIncludingOverview: function () {
                var thisControl = this;
                var returnedFeatures = thisControl.Analysis === null ? thisControl.DataTableResponse.featureDataTables : thisControl.Analysis.featureAnalysis;
                var result = thisControl.UserSelection.extendedFeatures
                    .filter(function (feature) {
                        return returnedFeatures[feature.featureReference] !== undefined;
                    })
                    .map(function (feature) {
                        return {
                            featureReference: feature.featureReference,
                            featureName: feature.featureName
                        }
                    }).sort(function (a, b) {
                        if (a.featureName < b.featureName)
                            return -1;
                        if (a.featureName > b.featureName)
                            return 1;
                        return 0;
                    });
                return result;
            },
            ComparisonDetails: function () {
                var thisControl = this;

                //thisControl.UserSelection.includedProviders = thisControl.CompareSelection;
                return {
                    selection: thisControl.UserSelection,
                    analysis: thisControl.CurrentAnalysis,
                    firstInsuredName: thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    //firstInsuredName: thisControl.UserSelection.primaryClient.title + " " + thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    //secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.title + " " + thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    providersInDataTables: thisControl.HasDataTables ? thisControl.UserSelection.includedProviders : null,
                    reportTitle: thisControl.ReportTitle,
                    ShouldIncludeDefinitions: true
                };
            },
            IsTermAssurance: {
                get: function () {
                    var termAssuranceTools = ['MPCIPro', 'MPPro', 'CIPlus', 'LifePro'];

                    return termAssuranceTools.indexOf(this.UserSelection.tool.toolReference) > -1;
                }
            },
        },
        mounted: function () {
            var thisControl = this;

            var customerLoaded = function (c) {
                var isClient1 = (
                    c.life1FirstName === thisControl.userSelection.primaryClient.forename
                    && c.life1LastName === thisControl.userSelection.primaryClient.surname
                    && c.life1Gender === helper.GetGender(thisControl.userSelection.primaryClient.classification)
                    && helper.GetDisplayableDate(c.life1DateOfBirth) === helper.GetDisplayableDate(thisControl.userSelection.primaryClient.dateOfBirth)
                )
                if (isClient1) {
                    thisControl.isValidCustomer = true;
                    thisControl.customerType = "PersonOne";
                    if (thisControl.userSelection.secondaryClient !== null) {
                        thisControl.customerType = "Both";
                    }
                }
                var isClient2 = (
                    c.life2FirstName === thisControl.userSelection.primaryClient.forename
                    && c.life2LastName === thisControl.userSelection.primaryClient.surname
                    && c.life2Gender === helper.GetGender(thisControl.userSelection.primaryClient.classification)
                    && helper.GetDisplayableDate(c.life2DateOfBirth) === helper.GetDisplayableDate(thisControl.userSelection.primaryClient.dateOfBirth)
                )
                if (isClient2) {
                    thisControl.isValidCustomer = true;
                    thisControl.customerType = "PersonTwo";
                }
                console.log(c);
            }
            if (this.UserSelection.PreAnalysis) {
                this.Analysis = this.UserSelection.PreAnalysis;
                delete this.UserSelection.PreAnalysis;
                for (var f = 0; f < thisControl.Analysis.providerFilters.length; f++) {
                    for (var o = 0; o < thisControl.Analysis.providerFilters[f].providerFilterOptions.length; o++) {
                        thisControl.SelectedCustomFilterOptionKeys.push(thisControl.Analysis.providerFilters[f].providerFilterOptions[o].reference);
                    }
                }
                this.IsLoading = true;
                fetch("/Customer/" + thisControl.UserSelection.customerReference)
                    .then(function (c) { return c.json() })
                    .then(function (c) {
                        customerLoaded(c);
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                    }
                    ).catch(function () {
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                    });
                return;
            }
            var waiting = 3;
            this.LoadingDescription = "Just a moment, we are preparing your comparison...";

            var done = function () {
                thisControl.advert = null;
                thisControl.IsLoading = false;
            }
            this.IsLoading = true;
            var performAnalysis = function () {
                var analysisUrl = "/Api/";
                var request = thisControl.UserSelection;

                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (response) {
                        thisControl.Analysis = response.analysis;
                        thisControl.AnalysisResponse = response;
                        thisControl.SelectedCustomFilterOptionKeys = [];
                        for (var f = 0; f < thisControl.Analysis.providerFilters.length; f++) {
                            for (var o = 0; o < thisControl.Analysis.providerFilters[f].providerFilterOptions.length; o++) {
                                thisControl.SelectedCustomFilterOptionKeys.push(thisControl.Analysis.providerFilters[f].providerFilterOptions[o].reference);
                            }
                        }
                        waiting--;
                        if (waiting === 0) {
                            if (response.creditDeducted && thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '') {
                                thisControl.SubscriptionService.update();
                                ElMessage({
                                    message: "Credit Deducted",
                                    type: 'success'
                                });
                            }
                            done();
                        }
                        if (thisControl.Analysis.analysisReferecnce) {
                            thisControl.$router.push({ name: 'AnalysisPage', params: { customerReference: thisControl.UserSelection.customerReference, analysisReferecnce: thisControl.Analysis.analysisReferecnce } });
                        }
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        waiting--;
                        if (waiting === 0)
                            done();
                        //alert("Problem Performing Analysis" + xhr.responseText);
                    }
                });
            };
            performAnalysis();

            $.ajax({
                type: 'GET',
                url: '/api/Journey/Update/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/50/NonPricing%20Analysis%20Screen",
                success: function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                },
            });

            fetch("/Customer/" + thisControl.userSelection.customerReference)
                .then(function (c) { return c.json() })
                .then(function (c) {
                    customerLoaded(c);
                    waiting--;
                    if (waiting == 0) {
                        done();
                    }
                }).catch(function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                });

            var adToShow = helper.GetAdvert(this.adverts, "WhenPerformingAnalysis", this.UserSelection);
            if (adToShow !== null) {
                this.advert = adToShow;
                waiting++;
                setTimeout(function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                }, adToShow.minimumDurationInSeconds * 1000);
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="holder">
        <div v-if="customers !== null" class="mainDivAddcustomer">
            <div>
                <span>
                    <label class="numbers">1</label>
                    <label class="clientDetails">Client details</label>
                </span>

                <span style="float:right">
                    <label class="percentageCompleted">20% completed</label>
                </span>
                <el-progress :percentage="20" style="padding-top:5px;"></el-progress>

            </div>

            <div>
                <h1 class="Title">What are the client details?</h1>
            </div>
            <p v-if="Client1Locked || Client2Locked">If you have generated a report for this client(s) you will not be able to change their name, date of birth or gender.</p>
            <el-form label-position="top" ref="ruleFormCustomers" :model="customers" :rules="rules">

                <el-form-item label="Title" prop="life1Title">
                    <el-select v-model="customers.life1Title" placeholder="Title">
                        <el-option label="Mr" value="MR" />
                        <el-option label="Mrs" value="MRS" />
                        <el-option label="Miss" value="MISS" />
                        <el-option label="Ms" value="MS" />
                        <el-option label="Dr" value="DR" />
                        <el-option label="Rev" value="REV" />
                    </el-select>
                </el-form-item>

                <el-form-item label="First Name" prop="life1FirstName">
                    <el-input :disabled="Client1Locked" placeholder="First Name" v-model="customers.life1FirstName"></el-input>
                </el-form-item>

                <el-form-item label="Last Name" prop="life1LastName">
                    <el-input :disabled="Client1Locked" placeholder="Last Name" v-model="customers.life1LastName"></el-input>
                </el-form-item>

                <el-form-item label="Date of birth" prop="life1DateOfBirth">
                    <el-date-picker :disabled="Client1Locked" type="date" format="DD-MM-YYYY" value-format="YYYY-MM-DD" placeholder="Pick a date" v-model="customers.life1DateOfBirth"></el-date-picker>
                </el-form-item>

                <div style="display: inline-flex;">

                    <el-form-item label="Smoking Status" prop="life1SmokingStatus">
                        <el-select v-model="customers.life1SmokingStatus" placeholder="Smoking Status">
                            <el-option label="Non-Smoker" value="_NonSmoker" />
                            <el-option label="Ex-Regular Smoker" value="_Former_Smoker" />
                            <el-option label="Smoker" value="_Smoker" />
                        </el-select>
                    </el-form-item>

                    <el-form-item  label="Gender" style="padding-left:10px;" prop="life1Gender">
                        <el-select :disabled="Client1Locked" v-model="customers.life1Gender" placeholder="Gender">
                            <el-option label="Male" value="Male" />
                            <el-option label="Female" value="Female" />
                        </el-select>
                    </el-form-item>
                </div>

                <el-form-item label="Employment status" prop="life1EmploymentStatus">
                    <el-select v-model="customers.life1EmploymentStatus" placeholder="Employment status">
                        <el-option label="Employed" value="Employed"></el-option>
                        <el-option label="Self-Employed" value="SelfEmployed"></el-option>
                        <el-option label="House Person" value="HousePerson"></el-option>
                        <el-option label="Retired" value="Retired"></el-option>
                        <el-option label="Unemployed" value="Unemployed"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Occupation" v-if="(customers.life1EmploymentStatus === 'Employed' || customers.life1EmploymentStatus === 'SelfEmployed')" prop="life1Occupation">
                    <el-select v-model="customers.life1Occupation" filterable placeholder="Start typing...">
                        <el-option v-for="(occupation, i) in occupations" :label="occupation.description" :value="occupation.code" :key="i"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Annual income or salary" v-if="customers.life1EmploymentStatus === 'Employed' || customers.life1EmploymentStatus === 'SelfEmployed'" prop="life1Salary">
                    <el-input placeholder="Salary" :integer="true" :min="1" :max="999999999" v-model.number="customers.life1Salary" controls-position="right">
                        <template #prefix>
                            £
                        </template>
                    </el-input>
                </el-form-item>
                <div style="display: flex; justify-content: space-between;margin-bottom:10px;">
                    <div>
                        <el-link type="primary" v-model="customers.isLifeTwo" @click="customers.isLifeTwo = true" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;">{{ customers.isLifeTwo == true ? 'Second life' :  'Add a second life' }}</el-link>
                    </div>
                    <div>
                        <el-link type="primary" v-if="customers.isLifeTwo && !Client2Locked" v-on:click="ResetSecondLife(customers.isLifeTwo = false)" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;">Remove</el-link>
                    </div>
                </div>

                <div v-if="customers !== null && customers.isLifeTwo">

                    <el-form-item label="Title" prop="life2Title">
                        <el-select v-model="customers.life2Title" placeholder="Title">
                            <el-option label="Mr" value="MR" />
                            <el-option label="Mrs" value="MRS" />
                            <el-option label="Miss" value="MISS" />
                            <el-option label="Ms" value="MS" />
                            <el-option label="Dr" value="DR" />
                            <el-option label="Rev" value="REV" />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="First Name" prop="life2FirstName">
                        <el-input :disabled="Client2Locked" placeholder="First Name" v-model="customers.life2FirstName"></el-input>
                    </el-form-item>

                    <el-form-item label="Last Name" prop="life2LastName">
                        <el-input  :disabled="Client2Locked" placeholder="Last Name" v-model="customers.life2LastName"></el-input>
                    </el-form-item>

                    <el-form-item label="Date of birth" prop="life2DateOfBirth">
                        <el-date-picker :disabled="Client2Locked" type="date" format="DD-MM-YYYY" value-format="YYYY-MM-DD" placeholder="Pick a date" v-model="customers.life2DateOfBirth"></el-date-picker>
                    </el-form-item>

                    <div style="display: inline-flex;">
                        <el-form-item label="Smoking Status" prop="life2SmokingStatus">
                            <el-select v-model="customers.life2SmokingStatus" placeholder="Smoking Status">
                                <el-option label="Non-Smoker" value="_NonSmoker" />
                                <el-option label="Ex-Regular Smoker" value="_Former_Smoker" />
                                <el-option label="Smoker" value="_Smoker" />
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Gender" style="padding-left:10px;" prop="life2Gender">
                            <el-select :disabled="Client2Locked"  v-model="customers.life2Gender" placeholder="Gender">
                                <el-option label="Male" value="Male" />
                                <el-option label="Female" value="Female" />
                            </el-select>
                        </el-form-item>
                    </div>

                    <el-form-item label="Employment status" prop="life2EmploymentStatus">
                        <el-select v-model="customers.life2EmploymentStatus" placeholder="Employment status">
                            <el-option label="Employed" value="Employed"></el-option>
                            <el-option label="Self-Employed" value="SelfEmployed"></el-option>
                            <el-option label="House Person" value="HousePerson"></el-option>
                            <el-option label="Retired" value="Retired"></el-option>
                            <el-option label="Unemployed" value="Unemployed"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Occupation" v-if="(customers.life2EmploymentStatus === 'Employed' || customers.life2EmploymentStatus === 'SelfEmployed')" prop="life2Occupation">
                        <el-select v-model="customers.life2Occupation" filterable placeholder="Start typing...">
                            <el-option v-for="(occupation, i) in occupations" :label="occupation.description" :value="occupation.code" :key="i"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Annual income or salary" v-if="customers.life2EmploymentStatus === 'Employed' || customers.life2EmploymentStatus === 'SelfEmployed'" prop="life2Salary">
                        <el-input placeholder="Salary" :integer="true" :min="1" :max="999999999" v-model.number="customers.life2Salary" controls-position="right">
                            <template #prefix>
                                £
                            </template>
                        </el-input>
                    </el-form-item>

                </div>

                <div class="navigation" style="padding-bottom:20px;">
                    <el-form-item>
                        <el-button :disabled="CheckValidation()" type="success" v-on:click.stop="SaveCustomers('ruleFormCustomers')">Next</el-button>
                    </el-form-item>
                </div>

            </el-form>

        </div>

        <div class="summary">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>


            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Quality of plans</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>
    </div>
</template>
<style>
    div.el-progress__text {
        display: none;
    }

    .el-date-picker__prev-btn {
        display:flex !important;
    }
    .el-date-picker__next-btn {
        display: flex !important;
    }

    .el-date-picker__header {
        display: block !important;
        vertical-align: middle !important;
        align-items: baseline !important;
    }

    .el-form-item__label {
        float: none !important;
        display: inline-block !important;
        text-align: left !important;
        font-family: Inter !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 18px !important;
        color: #0f172a !important;
    }
</style>
<style scoped>
    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .mainDivAddcustomer {
            margin-left: -180px;
        }

        .summary {
            left: -180px;
        }
    }
    p {
        color: #4B5768;
    }
    .holder {
        max-width: 1044px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 110px;
        position: relative;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        top: 0;
        right: 150px;
        width: 232px;
        height: 572px;
        display: block;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .el-input__inner {
        color: black;
    }

    .el-progress__text {
        display: none !important;
    }

    .el-progress-bar__inner {
        padding-top: 5px;
        color: #1E228C
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .clientDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
    }

    .el-progress--without-text {
        display: none !important;
    }

    .el-input {
        width: 366px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        position: static;
        width: 167px !important;
        height: 44px;
        border-radius: 8px;
        float: right !important;
        background: #059669 !important;
        /*border-color: #059669;*/
    }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669 !important;
        border-radius: 8px;
        /*border-color: #059669;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px;
        /*border-color: #E7EAEE;*/
        font-style: normal;
        color: black;
    }

    .btnParaCover {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        position: static;
        width: 367px;
        height: 44px;
        left: 82px;
        top: 935px;
    }

    .datePickerField {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        padding: 10px 12px 10px 16px !important;
        height: 48px !important;
        background: #FFFFFF !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
    }

    .mainDivAddcustomer {
        width: 531px;
        padding: 42px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .Title {
        width: 366px;
        height: 20px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .labels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #0F172A;
    }

    .input {
        padding: 10px 12px 10px 16px;
        width: 366px !important;
        height: 48px !important;
        left: 82px;
        top: 229px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 56px;
        display: flex;
        align-items: center;
    }

    .DropdownControl {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        position: absolute;
        width: 166px !important;
        height: 44px !important;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .btnNext {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 167px;
        height: 44px;
        left: 200px;
        top: 0px;
        background: #059669 !important;
        border-radius: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 14px;
        float: right !important;
    }

    .el-form-item__label {
        float: none !important;
        text-align: left !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        color: #0F172A !important;
    }

    .el-input__inner {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
    }
</style>

<script>
    import $ from 'jquery';

    export default {
        name: 'AddCustomer',
        props: ["userSelection", "userDetails"],
        inject: ["SubscriptionService"],
        computed: {
            UserSelection: { get: function () { return this.userSelection; } },
            UserDetails: { get: function () { return this.userDetails; } },
            Client1Locked: {
                get: function () { return this.lockedStatus === "Person1Locked" || this.lockedStatus === "BothLocked" }
            },
            Client2Locked: {
                get: function () { return this.lockedStatus === "Person2Locked" || this.lockedStatus === "BothLocked" }
            },
        },
        components: {
        },
        data: function () {
            return {
                occupations: [],
                lockedStatus: "NeitherLocked",
                customers: {
                    isLifeTwo: false,
                    life1Gender: '', life1SmokingStatus: '', life1DateOfBirth: '1990-01-01', life1FirstName: '', life1LastName: '', life1EmploymentStatus: '', life1Salary: '0', life1Occupation: '', life1Title: '',
                    life2Gender: '', life2SmokingStatus: '', life2DateOfBirth: '1990-01-01', life2FirstName: '', life2LastName: '', life2EmploymentStatus: '', life2Salary: '0', life2Occupation: '', life2Title: ''
                },
                SelectedCustomer: null,
                ruleFormCustomers: {
                    life1FirstName: '',
                    life1LastName: '',
                    life1DateOfBirth: '',
                    life1SmokingStatus: '',
                    life1Gender: '',
                    life1Title: '',
                    life1EmploymentStatus: '',
                    life1Occupation: '',
                    life1Salary: '',
                    life2FirstName: '',
                    life2LastName: '',
                    life2DateOfBirth: '',
                    life2SmokingStatus: '',
                    life2Gender: '',
                    life2Title: '',
                    life2EmploymentStatus: '',
                    life2Occupation: '',
                    life2Salary: ''
                },
                rules: {
                    life1FirstName: [
                        { required: true, message: 'Please input first name', trigger: 'change' },
                        { min: 1, max: 35, message: 'First name should be 1 to 35', trigger: 'change' }
                    ],
                    life1LastName: [
                        { required: true, message: 'Please input last name', trigger: 'change' },
                        { min: 1, max: 35, message: 'Last name should be 1 to 35', trigger: 'change' }
                    ],
                    life1DateOfBirth: [
                        { required: true, message: 'Please select date of birth', trigger: 'change' }
                    ],
                    life1SmokingStatus: [
                        { required: true, message: 'Please select smoking status', trigger: 'change' }
                    ],
                    life1Gender: [
                        { required: true, message: 'Please select gender', trigger: 'change' }
                    ],
                    life1Title: [
                        { required: true, message: 'Please select title', trigger: 'change' }
                    ],
                    life1EmploymentStatus: [
                        { required: true, message: 'Please select employment status', trigger: 'change' }
                    ],
                    life1Occupation: [
                        { required: true, message: 'Please select occupation', trigger: 'change' }
                    ],
                    life1Salary: [
                        { required: true, message: 'Please input salary', trigger: 'change' }
                    ],
                    life2FirstName: [
                        { required: true, message: 'Please input first name', trigger: 'change' },
                        { min: 1, max: 35, message: 'First name should be 1 to 35', trigger: 'change' }
                    ],
                    life2LastName: [
                        { required: true, message: 'Please input last name', trigger: 'change' },
                        { min: 1, max: 35, message: 'Last name should be 1 to 35', trigger: 'change' }
                    ],
                    life2DateOfBirth: [
                        { required: true, message: 'Please select date of birth', trigger: 'change' }
                    ],
                    life2SmokingStatus: [
                        { required: true, message: 'Please select smoking status', trigger: 'change' }
                    ],
                    life2Gender: [
                        { required: true, message: 'Please select gender', trigger: 'change' }
                    ],
                    life2Title: [
                        { required: true, message: 'Please select title', trigger: 'change' }
                    ],
                    life2EmploymentStatus: [
                        { required: true, message: 'Please select employment status', trigger: 'change' }
                    ],
                    life2Occupation: [
                        { required: true, message: 'Please select occupation', trigger: 'change' }
                    ],
                    life2Salary: [
                        { required: true, message: 'Please input salary', trigger: 'change' }
                    ],

                }
            }
        },
        methods: {
            displayDate: function (d) {
                if (d === null)
                    return "-";
                return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
            },
            EditCustomer: function (customerReference) {
                var thisControl = this;
                this.customers = null;
                $.ajax({
                    type: 'GET',
                    url: '/Customer/' + customerReference,
                    data: {},
                    success: function (customer) {
                        if (customer.customerReference === null)
                            customer.customerReference = customerReference;

                        thisControl.customers = customer;

                        if (thisControl.customers.life1DateOfBirth !== null)
                            thisControl.customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                        if (thisControl.customers.life2DateOfBirth !== null)
                            thisControl.customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$message.error("Error updating customer: " + xhr.responseText);
                    }
                });
            },
            SaveCustomers: function (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //this.$message.success('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

                var thisControl = this;
                $.ajax({
                    type: 'PUT',
                    url: "/Customer/",
                    data: JSON.stringify({

                        customerReference: thisControl.customers.customerReference,
                        life1Title: thisControl.customers.life1Title,
                        life1FirstName: thisControl.customers.life1FirstName,
                        life1LastName: thisControl.customers.life1LastName,
                        life1Gender: thisControl.customers.life1Gender,
                        life1SmokingStatus: thisControl.customers.life1SmokingStatus,
                        life1DateOfBirth: thisControl.customers.life1DateOfBirth,
                        life1Occupation: thisControl.customers.life1Occupation,
                        life1Salary: thisControl.customers.life1Salary,
                        IsLife1IsLocaked: thisControl.customers.isLife1IsLocaked,
                        life1EmploymentStatus: thisControl.customers.life1EmploymentStatus,

                        isLifeTwo: thisControl.customers.isLifeTwo,

                        life2Title: thisControl.customers.life2Title,
                        life2FirstName: thisControl.customers.life2FirstName,
                        life2LastName: thisControl.customers.life2LastName,
                        life2Gender: thisControl.customers.life2Gender,
                        life2SmokingStatus: thisControl.customers.life2SmokingStatus,
                        life2DateOfBirth: thisControl.customers.life2DateOfBirth,
                        life2Occupation: thisControl.customers.life2Occupation,
                        life2Salary: thisControl.customers.life2Salary,
                        IsLife2IsLocaked: thisControl.customers.isLife2IsLocaked,
                        life2EmploymentStatus: thisControl.customers.life2EmploymentStatus,

                    }),
                    contentType: 'application/json',
                    success: function (customerReference) {
                        thisControl.UserSelection.customerReference = customerReference;
                        thisControl.$emit("save-customer");
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                    }
                });
            },
            LoadAnalysis: function (customerReference) {
                this.UserSelection.selectedCustomerType = null;
                this.UserSelection.customerReference = customerReference;
                this.$emit('load-analysis-selected');
            },
            GetOccupations: function () {
                var thisControl = this;

                $.ajax({
                    type: 'GET',
                    url: '/api/Pricing/Occupations/',
                    contentType: 'application/json',
                    success: function (data) {
                        thisControl.occupations = data;
                        var matches = thisControl.occupations.filter(function (v) {
                            return v.code === thisControl.value
                        });

                        if (matches.length > 0)
                            thisControl.displayValue = matches[0].description;

                        //data.forEach((entry) => {
                        //    thisControl.occupations.push(entry.description);
                        //});
                    }
                });
            },
            CheckValidation: function () {
                if (this.customers.life1Gender === null || this.customers.life1Gender === "")
                    return true;

                if (this.customers.life1Title === null || this.customers.life1Title === "" || this.customers.life1Title === undefined)
                    return true;

                if (this.customers.life1SmokingStatus === null || this.customers.life1SmokingStatus === "")
                    return true;

                if (this.customers.life1FirstName === null || this.customers.life1FirstName === "")
                    return true;

                if (this.customers.life1LastName === null || this.customers.life1LastName === "")
                    return true;

                if (this.customers.life1EmploymentStatus === null || this.customers.life1EmploymentStatus === "")
                    return true;

                if (this.customers.life1EmploymentStatus === "Employed" || this.customers.life1EmploymentStatus === "SelfEmployed") {
                    if (this.customers.life1Salary === null || this.customers.life1Salary === "" || this.customers.life1Salary === "0")
                        return true;
                    if (this.UserDetails.pricingWebID !== null)
                        if (this.customers.life1Occupation === null || this.customers.life1Occupation === "")
                            return true;
                }

                if (this.customers.isLifeTwo) {
                    if (this.customers.life2Gender === null || this.customers.life2Gender === '')
                        return true;

                    if (this.customers.life2Title === null || this.customers.life2Title === '' || this.customers.life2Title === undefined)
                    return true;

                    if (this.customers.life2SmokingStatus === null || this.customers.life2SmokingStatus === '')
                        return true;

                    if (this.customers.life2FirstName === null || this.customers.life2FirstName === '')
                        return true;

                    if (this.customers.life2LastName === null || this.customers.life2LastName === '')
                        return true;

                    if (this.customers.life2EmploymentStatus === null || this.customers.life2EmploymentStatus === "")
                        return true;

                    if (this.customers.life2EmploymentStatus === "Employed" || this.customers.life2EmploymentStatus === 'SelfEmployed') {

                        if (this.customers.life2Salary === null || this.customers.life2Salary === "" || this.customers.life2Salary === "0")
                            return true;
                        if (this.UserDetails.pricingWebID !== null)
                            if (this.customers.life2Occupation === null || this.customers.life2Occupation === '')
                                return true;
                    }

                }

                return false;
            },
            GetCustomer: function () {
                var thisControl = this;
                this.customers = null;

                $.ajax({
                    type: 'GET',
                    url: '/Customer/' + thisControl.UserSelection.customerReference,
                    data: {},
                    success: function (customer) {
                        if (customer.customerReference === null)
                            customer.customerReference = thisControl.UserSelection.customerReference;

                        thisControl.customers = customer;

                        if (thisControl.customers.life1DateOfBirth !== null)
                            thisControl.customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                        if (thisControl.customers.life2DateOfBirth !== null)
                            thisControl.customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                        thisControl.UserSelection.selectedCustomerType = 0;
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$message.error("Error updating customer: " + xhr.responseText);
                    }
                });

                this.SubscriptionService.getCustomerLockedStatus(thisControl.UserSelection.customerReference)
                    .then(function (lockedStatus) {
                        thisControl.lockedStatus = lockedStatus;
                    })

                return this.customers;
            },
            ResetSecondLife: function () {
                this.customers.life2Gender = '';
                this.customers.life2Life = '';
                this.customers.life2SmokingStatus = '';
                this.customers.life2FirstName = '';
                this.customers.life2LastName = '';
                this.customers.life2EmploymentStatus = '';
                this.customers.life2Salary = '0';
                this.customers.life2DateOfBirth = '1990-01-01';
                this.customers.life2Occupation = '';
            },
        },
        created: function () {

            if (this.occupations.length == 0)
                this.GetOccupations();

            this.customers = {
                isLifeTwo: false,
                life1Gender: '', life1SmokingStatus: '', life1DateOfBirth: '1990-01-01', life1FirstName: '', life1LastName: '', life1EmploymentStatus: '', life1Salary: '0', life1Occupation: '',
                life2Gender: '', life2SmokingStatus: '', life2DateOfBirth: '1990-01-01', life2FirstName: '', life2LastName: '', life2EmploymentStatus: '', life2Salary: '0', life2Occupation: ''
            };

            this.UserSelection.selectedCustomerType = null;

            if (this.UserSelection.customerReference !== null && this.UserSelection.customerReference !== "")
                this.GetCustomer();

            if (this.customers === null || this.customers.length === 0) {
                this.customers = {
                    isLifeTwo: false,
                    life1Gender: '', life1SmokingStatus: '', life1DateOfBirth: '1990-01-01', life1FirstName: '', life1LastName: '', life1EmploymentStatus: '', life1Salary: '0', life1Occupation: '',
                    life2Gender: '', life2SmokingStatus: '', life2DateOfBirth: '1990-01-01', life2FirstName: '', life2LastName: '', life2EmploymentStatus: '', life2Salary: '0', life2Occupation: ''
                };
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
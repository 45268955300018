<template>
    <el-dialog title="Analysis breakdown" width="750px" v-model="IsVisible">
        <div>
            <table class="Breakdown">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th v-for="(p) in ProviderSelection" :key="p.providerReference">{{p.providerName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Overall Quality Score</td>
                        <td v-for="(p) in ProviderSelection" :key="'Overall' + p.providerReference">
                            {{Round(Analysis.providerScores[p.providerReference])}}
                        </td>
                    </tr>
                    <tr class="separaterRow">
                        <td :colspan="1 + ProviderSelection.length">
                            Underlying Feature Quality Scores
                        </td>
                    </tr>
                </tbody>
                <tbody v-for="(categoryAnalysis, categoryName) in Analysis.categoryAnalysis" :key="categoryName">
                    <tr class="categoryHeaderRow">
                        <td class="QuestionLevel0">
                            {{categoryName}}
                        </td>
                        <td v-for="(p) in ProviderSelection" :key="'Category' + categoryName + '~' + p.providerReference">
                            {{Round(categoryAnalysis.providerScores[p.providerReference])}}
                        </td>
                    </tr>
                    <tr v-for="feature in SortedArray(categoryAnalysis.featureAnalysis)" :key="categoryName + '~' + feature.featureReference">
                        <td class="QuestionLevel0">{{GetName(feature.featureReference)}}</td>
                        <td v-for="(p) in ProviderSelection" :key="'Category' + categoryName + '~' + feature.featureReference + '~' + p.providerReference">
                            {{Round(feature.providerScores[p.providerReference])}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
     </el-dialog>
</template>
<style scoped>
    table, td, th {
        border-collapse: collapse;
    }

    .header {
        border-bottom: none !important;
    }

    .Options {
        padding-right: 0px !important;
    }

    .ModalBackGroundPanel {
        overflow-y: auto !important;
    }

    .Title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    table.Breakdown {
        width: 100%;
        border: 0px solid #181F6A;
        table-layout: fixed
    }

        table.Breakdown thead {
            width: 866px;
            height: 78px;
        }

            table.Breakdown thead tr th {
                text-align: center;
                background: #181F6A;
                border: 1px solid #181F6A;
                vertical-align: middle;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                align-items: center;
                color: #FFFFFF;
            }

        table.Breakdown tbody tr td {
            text-align: center;
            vertical-align: middle;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #323A46;
            padding: 5px;
        }

            table.Breakdown tbody tr td:first-child {
                text-align: left;
                padding: 10px;
            }

        table.Breakdown tbody tr td, table.Breakdown thead tr th {
            height: 30px;
        }

    .Breakdown tr.separaterRow td {
        background: #BBCAF3;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        color: #323A46;
        position: relative;
        width: 775px;
        height: 40px;
        text-transform: uppercase;
    }

    .Breakdown tr.categoryHeaderRow td {
        background: #F7F8F9;
    }

    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
<script>

    export default {
        name: 'AnalysisBreakdown',
        components: {},
        props: ["analysis", "providerSelection", "extendedFeatures"],
        data: function () {
            return {
                IsVisible: true
            }
        },
        watch: {
            IsVisible: function (newValue) {
                if (newValue === false) {
                    this.$emit('closed')
                }
            }
        },
        methods: {
            Round: function (value) {
                return parseFloat(value).toFixed(1);

            },
            GetName: function (reference) {
                return this.ExtendedFeatures.filter(function (a) { return a.featureReference === reference })[0].featureName;
            },
            SortedArray: function (featureAnalysis) {
                return Object.entries(featureAnalysis)
                    .map(([featureReference, feature]) => ({
                        ...feature,
                        featureReference
                    }))
                    .sort((a, b) => b.featureWeight - a.featureWeight);
            }
        },
        created: function () {

        },
        computed:
        {
            Analysis: function () { return this.analysis; },
            ProviderSelection: function () { return this.providerSelection; },
            ExtendedFeatures: function () { return this.extendedFeatures; },
        },
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->



<script>

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }
    var regex = /^([0-9])/;
    export default
        {
            standardChartColors: [
                "#3366cc",
                "#dc3912",
                "#ff9900",
                "#109618",
                "#990099",
                "#0099c6",
                "#dd4477",
                "#66aa00",
                "#b82e2e",
                "#316395",
                "#994499",
                "#22aa99",
                "#aaaa11",
                "#6633cc",
                "#e67300",
                "#8b0707",
                "#651067",
                "#329262",
                "#5574a6",
                "#3b3eac",
                "#b77322",
                "#16d620",
                "#b91383",
                "#f4359e",
                "#9c5935",
                "#a9c413",
                "#2a778d",
                "#668d1c",
                "#bea413",
                "#0c5922",
                "#743411"],
            checkCondition: function (condition, values) {
                var thisControl = this;
                if (condition === null)
                    return true;
                var value = null;
                var comparisonValue = null;
                if (condition.field !== null) {
                    switch (condition.field) {
                        case "PrimaryClientAge":
                        case "SecondaryClientAge":
                        case "NumberOfChildren":
                        case "PolicyTerm":
                        case "ToAge":
                        case "PrimaryClientAgeAtEnd":
                        case "SecondaryClientAgeAtEnd":
                        case "MaxClientAge":
                        case "MinClientAge":
                        case "MaxClientAgeAtEnd":
                        case "MinClientAgeAtEnd":
                        case "CIAmountAssured":
                        case "PolicyAmountAssured":
                        case "YoungestChildAge":
                        case "OldestChildAge":
                            value = values[condition.field];
                            comparisonValue = condition.intComparisonValue;
                            break;
                        case "PrimaryClientGender":
                        case "SecondaryClientGender":
                        case "PrimaryClientSmokingStatus":
                        case "SecondaryClientSmokingStatus":
                        case "PolicyType":
                        case "PolicyTermType":
                        case "ToolReference":
                            value = values[condition.field];
                            comparisonValue = condition.stringComparisonValue;
                            break;
                        case "CustomField":

                            value = values.CustomFields[condition.customFieldName];
                            comparisonValue = condition.stringComparisonValue;
                            break;
                        case "PolicyAmountAssuredIsMaximum":
                            value = values[condition.field];
                            comparisonValue = condition.boolComparisonValue;
                            break;
                    }

                }
                if (typeof (condition.comparisonTypes) === "undefined")
                    condition.comparisonTypes = condition.comparisonType;
                switch (condition.comparisonTypes) {
                    case "IsEqualTo":
                        return value == comparisonValue;
                    case "IsEqualToOrGreaterThan":
                        return value >= comparisonValue;
                    case "IsEqualToOrLessThan":
                        return value <= comparisonValue;
                    case "IsGreaterThan":
                        return value > comparisonValue;
                    case "IsLessThan":
                        return value < comparisonValue;
                    case "IsNotEqualTo":
                        return value != comparisonValue;
                    case "IsOneOf":
                        return condition.possibleValues.some(function (v) { return v === comparisonValue })
                    case "IsNotOneOf":
                        return !condition.possibleValues.some(function (v) { return v == comparisonValue })
                    case "AnyOfTheFollowingCriteriaIsMet":
                        if (typeof (condition.subComparisons) === "undefined")
                            condition.subComparisons = condition.subConditions
                        return condition.subComparisons.some(function (c) { return thisControl.checkCondition(c, values) })
                    case "AllOfFollowingCriteriaIsMet":
                        if (typeof (condition.subComparisons) === "undefined")
                            condition.subComparisons = condition.subConditions
                        return !condition.subComparisons.some(function (c) { return !thisControl.checkCondition(c, values) })
                }
            },
            pusharray: function (array, arrayToAdd) {
                for (var i = 0; i < arrayToAdd.length; i++) {
                    array.push(arrayToAdd[i]);
                }
            },
            copyElementContents: function (el) {
                var body = document.body, range, sel;
                if (document.createRange && window.getSelection) {
                    range = document.createRange();
                    sel = window.getSelection();
                    sel.removeAllRanges();
                    try {
                        range.selectNodeContents(el);
                        sel.addRange(range);
                    } catch (e) {
                        range.selectNode(el);
                        sel.addRange(range);
                    }
                } else if (body.createTextRange) {
                    range = body.createTextRange();
                    range.moveToElementText(el);
                    range.select();
                }

                document.execCommand("Copy");
            },
            copyArrayToClipboard: function (arrOfArr) {
                var thead;
                var tr;
                var table;
                var th;
                var td;
                var tbody;
                table = document.createElement("table");
                thead = document.createElement("thead");
                table.appendChild(thead);
                tr = document.createElement("tr");
                thead.appendChild(tr);
                for (var c = 0; c < arrOfArr[0].length; c++) {
                    th = document.createElement("th");
                    tr.appendChild(th);
                    th.appendChild(document.createTextNode(arrOfArr[0][c]));
                }
                tbody = document.createElement("tbody");
                table.appendChild(tbody);
                for (var r = 1; r < arrOfArr.length; r++) {
                    tr = document.createElement("tr");
                    tbody.appendChild(tr);
                    for (var d = 0; d < arrOfArr[r].length; d++) {
                        td = document.createElement("td");
                        tr.appendChild(td);
                        td.appendChild(document.createTextNode(arrOfArr[r][d]));
                    }
                }
                document.body.appendChild(table);
                this.copyElementContents(table);
                document.body.removeChild(table);

            },
            mergeArrays: function (arrayOfArray) {
                var result = [];
                for (var i = 0; i < arrayOfArray.length; i++) {
                    this.pusharray(result, arrayOfArray[i]);
                }
                return result;
            },
            copyImageToClipboard: function (imageURI) {
                var div = document.createElement("div");
                var image = document.createElement("img");
                image.src = imageURI;
                div.appendChild(image);
                div.contentEditable = true;
                document.body.appendChild(div);
                this.copyElementContents(div);
                document.body.removeChild(div);
            },
            removeStylingColumns: function (data) {
                return data.map(
                    function (arr) {
                        return arr.filter(function (v, i) {
                            return typeof (data[0][i]) === "string"
                        })
                    }
                );
            },
            exists: function (arr, search) {
                return arr.some(function (row) { return row.includes(search); });
            },
            distinct: function (value, index, self) {
                return self.indexOf(value) === index;
            },
            makeblob: function (base64, contentType) {
                var byteCharacters = window.atob(base64);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);

                var blob = new Blob([byteArray], { type: contentType });
                return blob;
            },
            downloadFile: function (contentType, filename, data, redirecturl) {

                if (window.navigator.msSaveBlob) {
                    var blob = this.makeblob(data, contentType);
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
                        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
                    var a = document.createElement("a");
                    if (isIOS) {
                        a.setAttribute("target", "_blank");
                        //window.open(redirecturl, '_blank');
                        //return;
                    }
                    else {
                        redirecturl = "data:" + contentType + ";base64," + escape(data);
                    }

                    a.href = redirecturl
                    a.setAttribute("download", filename);

                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                }
            },
            customSort: function (fieldName) {
                return function (a, b) {
                    if (regex.test(a[fieldName]) && regex.test(b[fieldName])) {
                        return (parseFloat(a[fieldName]) - parseFloat(b[fieldName]))
                    }
                    return a[fieldName].localeCompare(b[fieldName]);
                }
            },
            groupByArray(xs, key) {
                return xs.reduce(
                    function (rv, x) {
                        var v = key instanceof Function ? key(x) : x[key];
                        var el = rv.find((r) => r && r.key === v);
                        if (el) {
                            el.values.push(x);
                        } else {
                            rv.push({ key: v, values: [x] });
                        } return rv;
                    }, []);
            },
            GetAge: function (dateString) {
                var today = new Date();
                var birthDate = new Date(dateString);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                return age;
            },
            GetGender: function (clientClassification) {
                if (clientClassification.startsWith("Male"))
                    return "Male";
                return "Female";
            },
            GetDisplayableDate: function (dateString) {
                if (typeof (dateString) === "string") {
                    if (dateString.length > 9) {
                        return dateString.substr(8, 2) + "/" + dateString.substr(5, 2) + "/" + dateString.substr(0, 4)
                    }
                }
                return "";
            },
            GetDisplayableClassification: function (clientClassification) {
                var gender = this.GetGender(clientClassification);
                var smokerStatus = this.GetSmokingStatus(clientClassification)
                {
                    switch (smokerStatus) {
                        case "_NonSmoker":
                            return gender + " Non-Smoker";
                        case "_Former_Smoker":
                            return gender + " Ex-Regular Smoker";
                        case "_Smoker":
                            return gender + " Smoker";
                        default:
                            return gender;
                    }
                }
            },
            GetSmokingStatus: function (clientClassification) {
                return clientClassification.replace(this.GetGender(clientClassification), "");
            },
            GetClientsName: function (userSelection) {
                switch (userSelection.tool.toolReference) {
                    case "IPPro - Pricing":
                        //This is always a single user scenario using title, firstname and surname

                        return (userSelection.primaryClient.forename + " " + userSelection.primaryClient.surname).trim();
                    case "LifePro - Pricing":
                    case "MPCIPro - Pricing":
                    case "MPPro - Pricing":
                    case "CIPlus - Pricing":
                        if (userSelection.secondaryClient === null) {
                            return (userSelection.primaryClient.forename + " " + userSelection.primaryClient.surname).trim()
                        }
                        else
                            return (userSelection.primaryClient.forename + " " + userSelection.primaryClient.surname).trim() + " and " + (userSelection.secondaryClient.forename + " " + userSelection.secondaryClient.surname).trim()
                    default:
                        if (userSelection.secondaryClient === null) {
                            return (userSelection.primaryClient.forename === null || userSelection.primaryClient.forename.trim() === "") ? "Client" : (userSelection.primaryClient.forename + " " + userSelection.primaryClient.surname).trim()
                        }
                        else {
                            return ((userSelection.primaryClient.forename === null || userSelection.primaryClient.forename.trim() === "") ? "Client 1" : (userSelection.primaryClient.forename + " " + userSelection.primaryClient.surname).trim())
                                + " and " +
                                `${userSelection.secondaryClient?.forename || ''} ${userSelection.secondaryClient?.surname || ''}`.trim() || 'Cient 2'
                            //((userSelection.secondaryClient.forename === null || userSelection.secondaryClient.forename.trim() === "") ? "Client 2" : (userSelection.secondaryClient.forename + " " + userSelection.secondaryClient.surname).trim())
                        }
                }
            },
            GetAdvert: function (adverts, screen, UserSelection) {
                if (adverts === undefined || adverts === null)
                    return null;

                var helper = this;
                var valuesForCondition = this.GetValuesForCondtion(UserSelection);
                //get ads for this screen
                adverts = adverts.filter(function (a) { return a.placementType === "AllScreens" || a.placementType === screen; });
                //remove ads that do not match conditions
                adverts = adverts.filter(function (a) { return helper.checkCondition(a.condition, valuesForCondition); });
                if (adverts.length == 0)
                    return null;
                var maxPriority = Math.max(...adverts.map(function (a) { return a.priority }));
                adverts = adverts.filter(function (a) { return a.priority === maxPriority; });

                var displayWeightSum = adverts.reduce(function (sumSoFar, advert) { return sumSoFar + advert.displayWeight }, 0);
                var selected = Math.random() * displayWeightSum;
                for (var a = 0; a < adverts.length; a++) {
                    if (adverts[a].displayWeight < selected)
                        return adverts[a];
                    selected -= adverts[a].displayWeight;
                }
                return adverts[0];
            },
            GetValuesForCondtion: function (UserSelection) {
                var helper = this;
                if (UserSelection.tool.toolReference === "IPPro") {
                    return {
                        ToolReference: UserSelection.tool.toolReference,
                        PrimaryClientAge: this.GetAge(UserSelection.primaryClient.dateOfBirth),
                        SecondaryClientAge: null,
                        NumberOfChildren: 0,
                        PolicyTerm: UserSelection.policyTerm,
                        ToAge: UserSelection.toAge,
                        PrimaryClientAgeAtEnd: UserSelection.toAge === null ? this.GetAge(UserSelection.primaryClient.dateOfBirth) + UserSelection.policyTerm : UserSelection.toAge,
                        SecondaryClientAgeAtEnd: null,
                        MaxClientAge: this.GetAge(UserSelection.primaryClient.dateOfBirth),
                        MinClientAge: this.GetAge(UserSelection.primaryClient.dateOfBirth),
                        MaxClientAgeAtEnd:
                            UserSelection.toAge !== null
                                ?
                                UserSelection.toAge
                                :
                                this.GetAge(UserSelection.primaryClient.dateOfBirth) + UserSelection.policyTerm,
                        MinClientAgeAtEnd:
                            UserSelection.toAge !== null
                                ?
                                UserSelection.toAge
                                :
                                this.GetAge(UserSelection.primaryClient.dateOfBirth) + UserSelection.policyTerm,
                        CIAmountAssured: 0,
                        PolicyAmountAssured: UserSelection.ipBenefitAmount,
                        YoungestChildAge: null,
                        OldestChildAge: null,
                        PrimaryClientGender: this.GetGender(UserSelection.primaryClient.classification),
                        SecondaryClientGender: null,
                        PrimaryClientSmokingStatus: this.GetSmokingStatus(UserSelection.primaryClient.classification),
                        SecondaryClientSmokingStatus: null,
                        PolicyType: "Single",
                        PolicyTermType: UserSelection.PolicyTermType,
                        PolicyAmountAssuredIsMaximum: UserSelection.ipBenefitBasis === "Maximum" ? true : false,
                        CustomFields: UserSelection.customFields
                    };
                }

                return {
                    ToolReference: UserSelection.tool.toolReference,
                    PrimaryClientAge: this.GetAge(UserSelection.primaryClient.dateOfBirth),
                    SecondaryClientAge: UserSelection.secondaryClient === null ? null : this.GetAge(UserSelection.secondaryClient.dateOfBirth),
                    NumberOfChildren: UserSelection.children.length,
                    PolicyTerm: UserSelection.policyTerm,
                    ToAge: UserSelection.toAge,
                    PrimaryClientAgeAtEnd: UserSelection.toAge === null ? this.GetAge(UserSelection.primaryClient.dateOfBirth) + UserSelection.policyTerm : UserSelection.toAge,
                    SecondaryClientAgeAtEnd: UserSelection.secondaryClient === null ? null : this.GetAge(UserSelection.secondaryClient.dateOfBirth) + UserSelection.policyTerm,
                    MaxClientAge: Math.max(this.GetAge(UserSelection.primaryClient.dateOfBirth), UserSelection.secondaryClient === null ? 0 : this.GetAge(UserSelection.secondaryClient.dateOfBirth)),
                    MinClientAge: Math.min(this.GetAge(UserSelection.primaryClient.dateOfBirth), UserSelection.secondaryClient === null ? 200 : this.GetAge(UserSelection.secondaryClient.dateOfBirth)),
                    MaxClientAgeAtEnd:
                        UserSelection.toAge !== null
                            ?
                            UserSelection.toAge
                            :
                            Math.max(this.GetAge(UserSelection.primaryClient.dateOfBirth) + UserSelection.policyTerm, UserSelection.secondaryClient === null ? 0 : this.GetAge(UserSelection.secondaryClient.dateOfBirth) + UserSelection.policyTerm),
                    MinClientAgeAtEnd: UserSelection.toAge !== null
                        ?
                        UserSelection.toAge
                        :
                        Math.min(this.GetAge(UserSelection.primaryClient.dateOfBirth) + UserSelection.policyTerm, UserSelection.secondaryClient === null ? 2000 : this.GetAge(UserSelection.secondaryClient.dateOfBirth) + UserSelection.policyTerm),
                    CIAmountAssured: UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null ? UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount : UserSelection.termBenefitAmount,
                    PolicyAmountAssured: UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null ? UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount : UserSelection.termCriticalIllnessCoverAmount,
                    YoungestChildAge: UserSelection.children.length === 0 ? null : Math.min(...UserSelection.children.map(function (c) { return helper.GetAge(c.dateOfBirth); })),
                    OldestChildAge: UserSelection.children.length === 0 ? null : Math.max(...UserSelection.children.map(function (c) { return helper.GetAge(c.dateOfBirth); })),
                    PrimaryClientGender: this.GetGender(UserSelection.primaryClient.classification),
                    SecondaryClientGender: UserSelection.secondaryClient === null ? null : this.GetGender(UserSelection.secondaryClient.classification),
                    PrimaryClientSmokingStatus: this.GetSmokingStatus(UserSelection.primaryClient.classification),
                    SecondaryClientSmokingStatus: UserSelection.secondaryClient === null ? null : this.GetSmokingStatus(UserSelection.secondaryClient.classification),
                    PolicyType: UserSelection.secondaryClient === null ? "Single" : "Joint",
                    PolicyTermType: UserSelection.PolicyTermType,
                    PolicyAmountAssuredIsMaximum: false,
                    CustomFields: UserSelection.customFields
                };
            },
            GetProviderLogoByName: function (providerName) {
                if (providerName.toLowerCase().includes("aviva"))
                    return "aviva";

                if (providerName.toLowerCase().includes("aegon"))
                    return "aegon";

                if (providerName.toLowerCase().includes("aig"))
                    return "aig";

                if (providerName.toLowerCase().includes("the exeter"))
                    return "the exeter";

                if (providerName.toLowerCase().includes("british friendly"))
                    return "british friendly";

                if (providerName.toLowerCase().includes("canada life"))
                    return "canada life";

                if (providerName.toLowerCase().includes("cirencester friendly"))
                    return "Cirencester Friendly";

                if (providerName.toLowerCase().includes("guardian"))
                    return "guardian";

                if (providerName.toLowerCase().includes("holloway friendly"))
                    return "Holloway Friendly";

                if (providerName.toLowerCase().includes("hsbc"))
                    return "hsbc";

                if (providerName.toLowerCase().includes("legal & general"))
                    return "Legal & General";

                if (providerName.toLowerCase().includes("lv"))
                    return "lv";

                if (providerName.toLowerCase().includes("lv="))
                    return "lv=";

                if (providerName.toLowerCase().includes("royal london"))
                    return "royal london";

                if (providerName.toLowerCase().includes("scottish widows"))
                    return "scottish widows";

                if (providerName.toLowerCase().includes("vitalitylife"))
                    return "VitalityLife";

                if (providerName.toLowerCase().includes("zurich"))
                    return "zurich";

                return providerName;
            },
            ApplyProductFilters: function (arrayToFilter, functionToGetFTRCProduct, providerFilters, selectedFilterOptionKeys,) {
                return arrayToFilter.filter(
                    function (p) {
                        var provider = functionToGetFTRCProduct(p, arrayToFilter);// thisControl.ProviderLookup[p.productID];
                        return !providerFilters.some(function (filter) {
                            var providerHasSomeFilter = filter.providerFilterOptions.some(
                                function (fo) {
                                    if (provider.providerFilters !== null) // not working
                                        return provider.providerFilters[filter.providerFilterReference].includes(fo.reference);
                                    else
                                        return null;
                                }
                            );

                            var providerHasSomeSelectedFilter = filter.providerFilterOptions.some(function (fo) {
                                if (provider.providerFilters !== null)
                                    return provider.providerFilters[filter.providerFilterReference]?.includes(fo.reference) && selectedFilterOptionKeys.includes(fo.reference);
                                else
                                    return null;
                            })
                            console.log(providerHasSomeSelectedFilter + " " + providerHasSomeFilter);
                            return !providerHasSomeSelectedFilter && providerHasSomeFilter;
                        });
                    }
                );
            }
        }
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
